import axios from "axios";
import { configs } from"../config";
import authHeader from "./auth-header";

const API_URL = configs.PROTOCOL + configs.DOMAIN + configs.AUTH_PORT + "/a/auth/";
const API_GROUPS_URL = configs.PROTOCOL + configs.DOMAIN + configs.AUTH_PORT + "/a/test/";

const register = (username, email, password, affiliation, groups, roles) => {

  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
    affiliation,
    groups,
  }, {
    headers: authHeader(),
  });
};

const login = (username, password) => {
  return axios
    .post(API_URL + "signin", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUserId = () => {
  if (localStorage.getItem("user")) {
  
    return JSON.parse(localStorage.getItem("user")).id;
  }
};
const getCurrentUserRole = () => {
  if (localStorage.getItem("user")) {

    return JSON.parse(localStorage.getItem("user")).roles[0];
  }
};

const getUserGroups = () => {
    if (localStorage.getItem("user")) {
    
        return axios.get(API_GROUPS_URL + "getUserGroups", {
          headers: authHeader(),
        });

    } else {
      return ({data: 
        {groups: ['all']}})
    }
};

export default {
  register,
  login,
  logout,
  getCurrentUserId,
  getCurrentUserRole,
  getUserGroups,
};
