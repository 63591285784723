import React, { useState } from "react";
import "../styles/Toogle.css";

function Toogle(props) {
  // eslint-disable-next-line
  const [Panels, setPanels] = useState([
    "Personalization",
    "Filters",
    "Views",
    "Users",
    "Custom CSS",
    "Analytics",
  ]);

  let defineView = (event) => {
    return props.defineView(event.target.id);
  };

  return (
    <div className="Toogle">
      {Panels.map((item) => {
        return (
          <div onClick={defineView} className="ToogleElement" id={item}>
            <p id={item}>{item}</p>
          </div>
        );
      })}
    </div>
  );
}

export default Toogle;
