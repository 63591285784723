import React, { useState, useEffect } from "react";
import "../styles/ViewSettings.css";
import { configs } from"../../../config";
import axios from "axios";

function Views(props) {
  const [data, setData] = useState({
    FormType: "views",
    Views: {
      name: "",
      description: "",
      identifier1: "",
      identifier2: "",
      identifier3: "",
      trimDescription: false
    },
    Table: {
      Column1: { attribute: "", displayName: "", type: "" },
      Column2: { attribute: "", displayName: "", type: "" },
      Column3: { attribute: "", displayName: "", type: "" },
      Column4: { attribute: "", displayName: "", type: "" },
    },
  });
  const [saveStatus, setSaveStatus] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    let query = { params: { view: "Views" } };
    axios
      .get(
        configs.PROTOCOL + configs.DOMAIN + configs.DATA_PORT + "/api/settings",
        query
      )
      .then((res) => {
        setData(res.data);
        setChecked(res.data.Views.trimDescription)
      });
  }, [props.view]);

  let save = () => {
    //Add new filter data to state
    //Send POST request with new filter list
    axios
      .post(
        configs.PROTOCOL + configs.DOMAIN + configs.DATA_PORT + "/api/settings",
        data
      )
      .then((res) => {});
    setSaveStatus(false);
  };

  let handleViewsChange = (event) => {
    let newViewsObject = data;
    
    if (event.target.id === "trimDescription") {
      newViewsObject.Views.trimDescription = event.target.checked;
    } else {
      newViewsObject.Views[event.target.id] = event.target.value;
    }
  
    setData(newViewsObject);
    setSaveStatus(true);
  };

  let handleTableChange = (event) => {
    let newTableObject = data;
    let arr = event.target.id.split(".");

    newTableObject.Table[arr[0]][arr[1]] = event.target.value;

    setData(newTableObject);
    setSaveStatus(true);
  };

  let changeTrimStatus = (event) => {
    let views = data;
   
    // if (views.Views.trimDescription == "on") {
    //   views.Views.trimDescription = "off"
    // } else {
    //   views.Views.trimDescription = "on"
    // }
    views.Views.trimDescription = event.target.checked
    setData(views)
  }

  //Function to be developed later ...

  // let generateTableRows = (type,columns) => {

  //   return (
  //     <tr>
  //     <td></td>
  //     <td>Column 1</td>
  //     <td>Column 2</td>
  //     <td>Column 3</td>
  //     <td>Column 4</td>
  //   </tr>
  //   )
  // }

  return (
    <div className="ViewSettings">
      <h3>Views</h3>
      {/*  */}
      <div className="ViewsWrapper">
        <h4>Main-view</h4>

        <form className="ViewsFormWrapper" onChange={handleViewsChange}>
          <label name="name">Name</label>
          <input
            className="viewsInput"
            type="text"
            id="name"
            defaultValue={data.Views.name}
          ></input>
          <label name="name">Description</label>
          <input
            className="viewsInput"
            type="text"
            id="description"
            defaultValue={data.Views.description}
          ></input>
          <label className="viewsInput" name="name">
            Identifier no.1
          </label>
          <input
            type="text"
            id="identifier1"
            defaultValue={data.Views.identifier1}
          ></input>
          <label className="viewsInput" name="name">
            Identifier no.2
          </label>
          <input
            type="text"
            id="identifier2"
            defaultValue={data.Views.identifier2}
          ></input>
          <label className="viewsInput" name="name">
            Identifier no.3
          </label>
          <input
            type="text"
            id="identifier3"
            defaultValue={data.Views.identifier3}
          ></input>
          <div>
          <label className="viewsInputCheckbox" name="name">
            Trim description
          </label>
          <input type="checkbox" className="checkboxElement" id="trimDescription" checked={checked} onChange={(e) => setChecked(e.target.checked)}></input>
          </div>
        </form>
        {saveStatus ? (
          <div className="saveViewsButton" onClick={save}>
            Save
          </div>
        ) : null}
      </div>
      {/* /// */}
      <div className="ViewsWrapper">
        <h4>Table-view</h4>

        <form className="ViewsFormWrapper" onChange={handleTableChange}>
          <table>
            <tr>
              <td></td>
              <td>Column 1</td>
              <td>Column 2</td>
              <td>Column 3</td>
              <td>Column 4</td>
            </tr>
            <tr>
              <td>Attribute</td>
              <td>
                <input
                  className="viewsInput"
                  type="text"
                  id="Column1.attribute"
                  defaultValue={data.Table.Column1.attribute}
                ></input>
              </td>
              <td>
                <input
                  className="viewsInput"
                  type="text"
                  id="Column2.attribute"
                  defaultValue={data.Table.Column2.attribute}
                ></input>
              </td>
              <td>
                <input
                  type="text"
                  id="Column3.attribute"
                  defaultValue={data.Table.Column3.attribute}
                ></input>
              </td>
              <td>
                <input
                  type="text"
                  id="Column4.attribute"
                  defaultValue={data.Table.Column4.attribute}
                ></input>
              </td>
            </tr>
            <tr>
              <td>Display name</td>
              <td>
                <input
                  className="viewsInput"
                  type="text"
                  id="Column1.displayName"
                  defaultValue={data.Table.Column1.displayName}
                ></input>
              </td>
              <td>
                <input
                  className="viewsInput"
                  type="text"
                  id="Column2.displayName"
                  defaultValue={data.Table.Column2.displayName}
                ></input>
              </td>
              <td>
                <input
                  className="viewsInput"
                  type="text"
                  id="Column3.displayName"
                  defaultValue={data.Table.Column3.displayName}
                ></input>
              </td>
              <td>
                <input
                  className="viewsInput"
                  type="text"
                  id="Column4.displayName"
                  defaultValue={data.Table.Column4.displayName}
                ></input>
              </td>
            </tr>{" "}
            <tr>
              <td>Type</td>
              <td>
                <input
                  className="viewsInput"
                  type="text"
                  id="Column1.type"
                  defaultValue={data.Table.Column1.type}
                ></input>
              </td>
              <td>
                <input
                  className="viewsInput"
                  type="text"
                  id="Column2.type"
                  defaultValue={data.Table.Column2.type}
                ></input>
              </td>
              <td>
                <input
                  className="viewsInput"
                  type="text"
                  id="Column3.type"
                  defaultValue={data.Table.Column3.type}
                ></input>
              </td>
              <td>
                <input
                  className="viewsInput"
                  type="text"
                  id="Column4.type"
                  defaultValue={data.Table.Column4.type}
                ></input>
              </td>
            </tr>
          </table>
        </form>
      </div>
    </div>
  );
}

export default Views;
