import React, { useState, useEffect } from "react";
import "../styles/CustomCSS.css";
import { configs } from"../../../config";
import axios from "axios";

function CustomCSS(props) {
  const [saved, saveData] = useState(false);
  const [changed, setChanged] = useState(false);
  const [data, setData] = useState("");

  useEffect(() => {
    axios.get(configs.PROTOCOL + configs.DOMAIN + "/css").then((res) => {
      setData(res.data);
    });
  }, [saved]);

  let changeState = (event) => {
    setData({ css: event.target.value });
    setChanged(true);
    saveData(false);
  };

  let save = () => {
    axios
      .post(
        configs.PROTOCOL + configs.DOMAIN + configs.DATA_PORT + "/api/customcss",
        data
      )
      .then((res) => {});
    setChanged(false);
    saveData(true);
  };

  return (
    <div className="customCSSwrapper">
      <p>You can add your own CSS rules below:</p>
      <form>
        <textarea
          className="customCSS"
          defaultValue={data}
          onChange={changeState}
        ></textarea>
        {changed ? (
          <button className="customCSSsave" onClick={save}>
            SAVE
          </button>
        ) : (
          ""
        )}
      </form>
    </div>
  );
}

export default CustomCSS;
