import React from "react";
import "../styles/FilterBlock.css";
import FilterDataset from "./FilterDataset";

class FilterElements extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: "",
      FilesList: [],
      isLoading: true,
      index: 0,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: false });
  }

  forceUpdate = (prop) => {
    this.setState({ index: prop });
  };

  createFilterBlocks(props) {
    let isFilterForDataSet = (element) => {
      return element.level === "data-set";
    };

    return this.props.filterList.filter(isFilterForDataSet).map((item) => (
      <React.Fragment key={item.name}>
        <FilterDataset
          name={item.name}
          type={item.type}
          dataType={item.dataType}
          displayName={item.displayName}
          filesList={this.props.filesList}
          filteredFiles={this.props.filteredFiles}
          isLoading={this.state.isLoading}
          aggregate={this.props.aggregate}
          lastFilterUsed={this.props.lastFilterUsed}
          query={this.props.query}
          index={this.state.index}
          forceUpdate={this.forceUpdate}
          isFiltered={this.props.isFiltered}
        />
      </React.Fragment>
    ));
  }

  render() {
    return (
      <div className="FilterWrapper">
        <div className="FilterHeader">Filters</div>
        {this.createFilterBlocks()}
      </div>
    );
  }
}

export default FilterElements;
