import React, { useState, useEffect } from "react";
import "../styles/ViewSettings.css";
import "../styles/Users.css";
import axios from "axios";
import UserService from "../../../services/user.service";

function EditUser(props) {
  const [userData, setUserData] = useState({
    username: "",
    email: "",
    affiliation: "",
    groups: "",
    roles: "",
  });
  const [isAdmin, setAdminRole] = useState();
  const [msg, setMsg] = useState();

  const getUserData = () => {
    UserService.getUserData(props._id)
      .then((res) => {
        setUserData(res.data);
        res.data.roles[0] === "admin"
          ? setAdminRole(true)
          : setAdminRole(false);
      })
      // .catch(() => {
      //   //console.log("Error occured during getting user data");
      //   //Need error handler
      // });
  };

  useEffect(() => {
    getUserData();
  }, [props._id]);



  const modifyState = (event) => {
    if (event.target.name === "admin") {
      if (event.target.checked === true) {
        setUserData({ ...userData, roles: ["admin"] });
        setAdminRole(true);
        return;
      } else {
        setUserData({ ...userData, roles: ["user"] });
        setAdminRole(false);
        return;
      }
    }
    if (event.target.name === "groups") {
      setUserData({ ...userData, groups: [event.target.value] });
    } else {
      setUserData({ ...userData, [event.target.name]: event.target.value });
    }
  };

  const updateUserData = () => {
    UserService.updateUserData(userData).then((res) => {
      setMsg(res.data);
    }).catch(function (error) {
      if (error.response) {
        if (error.response.data.error) {
          setMsg(error.response.data.error);
        } else {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
        setMsg("Error occured when updating user");
        }
     
      }
  });
  };

  const removeUser = () => {
    UserService.removeUser(props._id).then(
      () => {
        window.location.href = "/admin";
      },
      (error) => {
       // console.log(error);
       //Need error handler
      }
    );
  };

   return (
    <div className="ViewSettings">
      <h3>Edit user:</h3>
      <form class="NewUserForm">
        <table onChange={modifyState}>
          <tr>
            <td>
              <label for="username">Name:</label>
            </td>
            <td>
              <input
                id="username"
                type="text"
                name="username"
                defaultValue={userData.username}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label for="email">email:</label>
            </td>
            <td>
              <input
                type="e-mail"
                name="email"
                defaultValue={userData.email}
              ></input>
            </td>
          </tr>
          <tr>
            <td>
              <label for="affiliation">Affiliation: </label>
            </td>
            <td>
              <input
                type="text"
                name="affiliation"
                defaultValue={userData.affiliation}
              ></input>
            </td>
          </tr>
          <tr>
            <td>
              <label for="groups">Groups</label>
            </td>
            <td>
              <input
                type="text"
                name="groups"
                defaultValue={userData.groups}
              ></input>
            </td>
          </tr>
          <tr>
            <td>
              <label for="admin">Admin</label>
            </td>
            <td>
              <input
                class="toggle"
                type="checkbox"
                name="admin"
                checked={isAdmin}
              />
            </td>
          </tr>
        </table>
        <div className="saveLink" id="save" onClick={updateUserData}>
          Save
        </div>
        <div className="removeLink" id="remove" onClick={removeUser}>
          Remove
        </div>
      </form>
      <div>
        <p>{msg}</p>
      </div>
    </div>
  );
}
export default EditUser;
