import React, { useState, useEffect, useReducer } from "react";
import DatePicker from "react-date-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { config } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import "../styles/FilterDataset.css";
import "./FilterDatasetItem";
import FilterDatasetItem from "./FilterDatasetItem";

function FilterDataset(props) {
  let unique = [];
  const name = props.name;

  config.autoAddCss = false
  // eslint-disable-next-line
  // const [unique, setUnique] = useState([]);
  const [filterValues, setFilterValues] = useState([]);
  const [date, setDate] = useState();

  const isUnique = (element) => {
    let names = Object.getOwnPropertyNames(element).filter((i) => {
      return element[i].constructor === Array;
    });
    if (props.type === "single values") {
      if (unique.indexOf(element[name]) === -1 && element.itemType === "file") {
        unique.push(element[name]);
      }
    }

    if (props.type === "multiple values") {
      names.map((i) => {
        if (element.itemType === "file" && i === name) {
          element[i].map((item) => {
            if (item.constructor === String && unique.indexOf(item) === -1) {
              unique.push(item);
            }
          });
        }
      });
    }
  };

  const findUnique = () => {
    let dataSource;

    if (Object.getOwnPropertyNames(props.query).length > 0) {
      if (
   
        Object.getOwnPropertyNames(props.query)[
          Object.getOwnPropertyNames(props.query).length - 1
        ] === name &&
        Object.getOwnPropertyNames(props.query).length === 1
        
      ) {
        dataSource = props.filesList;
      } else {
        dataSource = props.filteredFiles;
      }
    } else {
      dataSource = props.filesList;
    }

    if (dataSource.length > 0) {
      dataSource.filter(isUnique);
    }
  };

  const createFilterList = (event) => {
    const checkIfExists = () => {
      return filterValues.indexOf(event.target.value) === -1;
    };
    const checkIndexInArray = () => {
      return filterValues.indexOf(event.target.value);
    };

    if (checkIfExists()) {
      filterValues.push(event.target.value);
    } else {
      filterValues.splice(checkIndexInArray(), 1);
    }

    props.forceUpdate(props.index + 1);
    handleFilterSubmit();
  };

  const handleFilterSubmit = () => {
    const prepareObject = () => {
      const filterObject = {};
      filterObject[props.name] = filterValues;
      return filterObject;
    };

    props.aggregate(prepareObject());
  };

  const handleDateChange = (date, type) => {
    let minMax;
    type === "date-start"
      ? (minMax = {
          min: date,
          max: null,
        })
      : (minMax = {
          min: null,
          max: date,
        });

    let dateObject = {
      start: minMax,
      status: type,
    };

    if (date === null) {
      props.aggregate({ start: [] });
    } else {
      props.aggregate(dateObject);
    }
  };

  let checkIfActive = (arrItem) => {
    const isActive = filterValues.indexOf(arrItem) !== -1;
    return props.isFiltered ? isActive : false;
  };

  const createInputs = () => {
    // Rendering function

    return unique.map((arrItem) => {
      if (arrItem!==undefined) {
        return (
          <FilterDatasetItem
            name={arrItem}
            createFilterList={createFilterList}
            isChecked={checkIfActive(arrItem)}
          />
        );
      } 
   
    });
  };

  const createForm = () => {
    let a;
    if (props.type === "single values" || props.type === "multiple values") {
      findUnique();
      a = createInputs();
    } else if (props.type === "date-start") {
      const type = "date-start";
      a = (
        <div className={"FilterChoices active"}>
          <DatePicker
            selected={date}
            value={date}
            onChange={(date) => setDate(date, handleDateChange(date, type))}
          />
        </div>
      );
    } else if (props.type === "date-end") {
      const type = "date-end";
      a = (
        <div className={"FilterChoices active"}>
          <DatePicker
            selected={date}
            value={date}
            onChange={(date) => setDate(date, handleDateChange(date, type))}
          />
        </div>
      );
    }
    return (
      <div className="FilterParamsWrapper">
        <div className="FilterParamsHeader">
          <div className="FilterCategory">
            {props.displayName}
            <div className="Chevron">
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
        </div>{" "}
        {a}{" "}
      </div>
    );
  };

  useEffect(() => {
    createForm();
  }, [filterValues, props.isFiltered]);

  if (!props.isLoading) {
    return createForm();
  } else {
    return (
      <div className="FilterParamsWrapper">
        <div className="FilterParamsHeader">
          <div className="FilterCategory">
            {props.displayName}
            <div className="Chevron">
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FilterDataset;
