import axios from "axios";
import authHeader from "./auth-header";
import { configs } from"../config";

const API_URL = configs.PROTOCOL + configs.DOMAIN + configs.AUTH_PORT + "/a/test/";

const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

const getUserBoard = (id) => {
  return axios.get(API_URL + "user", {
    headers: authHeader(),
    params: { _id: id },
  });
};

const getAdminBoard = (id) => {
  return axios.get(API_URL + "admin", {
    headers: authHeader(),
    params: { _id: id },
  });
};

const getAdminUsers = () => {
  
  return axios.get(API_URL + "list_users", { headers: authHeader() });
};

const getUserData = (id) => {
  return axios.get(API_URL + "get_user_data", {
    headers: authHeader(),
    params: { _id: id },
  });
};

const updateUserData = (userData) => {

  return axios({
    method: 'post',
    url: API_URL + "update_user_data",  
    data : userData,
    headers: authHeader(),
  });

  // return axios.post(
  //   API_URL + "update_user_data",
  //   {
  //     headers: authHeader(),
  //   },
  //  {data: userData},
  // );
};

const removeUser = (id) => {
  return axios.delete(API_URL + "remove_user", {
    headers: authHeader(),
    params: {
      _id: id,
    },
  });
};

function isLogin() {
  return axios.get(API_URL + "verify", {
    headers: authHeader(),
  });
}

const updatePassword = (req_id, req_password) => {

  let postData = {
    id : req_id,
    password: req_password
  }
  
  return axios({
    method: 'post',
    url: API_URL + "updatePassword",
    data: postData,
    headers: authHeader()
});

}



export default {
  getPublicContent,
  getUserBoard,
  getAdminBoard,
  getAdminUsers,
  getUserData,
  removeUser,
  updateUserData,
  isLogin,
  updatePassword
};
