import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import UserService from "../services/user.service";
import AuthService from "../services/auth.service";

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const fetchData = () => {
    //you need to add try catch here
    UserService.isLogin()
  
      .then((res) => {
        if (res.status === 200) {
       
          setIsAuth(true);
          setLoaded(true);
        }
      })
      .catch((err) => {
        if (err) {
          setIsAuth(false);
          setLoaded(true);
          AuthService.logout();
        } else {
          setLoaded(false);
          return;
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    loaded ? (
      <Route
        {...rest}
        render={(props) =>
          isAuth && restricted ? (
            <Redirect to="/user" />
          ) : (
            <Component {...props} />
          )
        }
      />
    ) : // ) : (
    //   <div>
    //     <p>Sorry for inconvinience. </p>
    //     <p>CIB-Platform is unavailable right now.</p>
    //     <p>Please come back later"</p>
    //   </div>
    // )
    null
  );
};

export default PublicRoute;
