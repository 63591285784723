import React, { useState, useEffect } from "react";
import "../styles/account.css";
import userService from "../../../services/user.service";
import authService from "../../../services/auth.service";
import dataService from "../../../services/data.service";

function DataSets(props) {
  const [login, checkLogin] = useState(false);
  const [userId, setUserId] = useState();
  const [groups, setGroups] = useState([]);


  userService.isLogin().then((res, err) => {
    if (res.status === 200) {
      checkLogin(true);
      setUserId(authService.getCurrentUserId())
    }
  }).catch((error) => {
    console.error(error);
  }) ;

  let checkGroups = () =>{  
    if (login) {
    authService.getUserGroups().then((res) => {
      setGroups(res.data.groups)
    });
   } else { 
    setGroups(['all'])
   }
  }
    useEffect(() => {
      checkGroups()
    }, [login]);

  let existingEntries = JSON.parse(localStorage.getItem("USER_ITEMS"));

  let removeFromDataSets = (event) => {
    let ind;

    existingEntries.map((a, index) => {
   
      if (a.id === event.target.id) {
        return (ind = index);
      } else {
        return null;
      }
    });
    existingEntries.splice(ind, 1);
  
    localStorage.setItem("USER_ITEMS", JSON.stringify(existingEntries));
  };

  let handleFileTransfer = (event) => {
    const name = event.target.getAttribute("name");
    dataService.downloadOne({ "_id": event.target.id, "name": name });
  };

  let findCommonElements = (arr1, arr2) => {
    if (arr1 && arr2) {
      return arr1.some((item) => arr2.includes(item));
    }
  };

  let downloadFile = (item) => {

    if ( groups.length > 0) {
    
    
          if (login && findCommonElements(
            item.allowedDownload,
            groups)) {
        
                return (
                  <div className="ActionLinks" id={item._id} name={item.id} onClick={handleFileTransfer}>
                    Download
                  </div>
                );
              
            }
        }

  };

  let listIds = () => {
    if (existingEntries !== null) {
      return existingEntries.map((item) => {
        if (item.userId===userId) {
          return (
            <tr className="DataSetRow">
              <td>
                <input type="checkbox"></input>
              </td>
              <td className="Column1">
                <span>{item.dataSet}</span>
              </td>
              <td className="Column2">{item.id}</td>
              <td className="Column3">
                {downloadFile(item)}
                {/* <div className="ActionLinks">View </div> */}
                <div
                  className="ActionLinks"
                  id={item.id}
                  onClick={removeFromDataSets}
                >
                  Delete
                </div>
              </td>
            </tr>
          );
        }
      });
    }
  };
  return (
    <div className="DataDashboard">
      <table className="DataSetTable">
        <tr className="DataSetHeader">
          <th></th>
          <th className="Column1"> Dataset</th>
          <th className="Column2">File name</th>
          <th className="Column3">Action</th>
        </tr>
        {listIds()}
      </table>
    </div>
  );
}

export default DataSets;
