import React, { useState, useEffect } from "react";
import axios from "axios";
import "../styles/ProjectView.css";
import { configs } from"../../../config";
import FilterElements from "./FilterElements";
import ProjectListView from "./ProjectListView.js";
import ProjectTableView from "./ProjectTableView.js";
import ProjectMapView from "./ProjectMapView.js";

function ProjectView(props) {
  const [view, setView] = useState("list");
  const [filesList, setFilesList] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [filters, addFilter] = useState({});
  const [query, createQuery] = useState({});
  const [filteredFiles, filterFiles] = useState(null);
  const [lastFilterUsed, setLastFilterUsed] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [numberOfFiles, setNumberOfFiles] = useState("");

  useEffect(() => {
    Object.getOwnPropertyNames(query).length === 0
      ? setIsFiltered(false)
      : setIsFiltered(true);
  }, [query]);

  useEffect(() => {
    retriveData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    countNumberOfFiles();
  }, [filesList]);

  useEffect(() => {
    createQuery(buildQuery());
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    filterFiles(filterData());
    // eslint-disable-next-line
  }, [query]);


  window.onpopstate = () => {
    props.goBackToMain();
  };

  let retriveData = () => {
    let query = { params: { name: props.viewParams.name } };
    axios
      .get(
        configs.PROTOCOL +
          configs.DOMAIN +
          configs.DATA_PORT +
          "/api/listprojectfiles",
        query
      )
      .then((res) => {
        let New = [];
        res.data.map((item) => {
          item.dsEntryId = item.dsEntryId.split(",");
          item.dsEntryId = item.dsEntryId[item.dsEntryId.length - 2];
          item.dsEntryId = item.dsEntryId.replace("name=", "");
        });

        setFilesList(res.data);
        setIsLoading(false);
      });
  };

  let navigateToList = () => {
    props.goBackToMain();
  };

  let handleSetView = (params) => {
    if (params === "list") {
      setIsFiltered(false);
    }
    setView(params);
  };

  let setLastFilter = (params) => {
    setLastFilterUsed(params);
  };

  let countNumberOfFiles = () => {
    setNumberOfFiles(filesList.length);
  };

  let aggregateFilters = (params) => {
    if (params.status === "date-start" || params.status === "date-end") {
      addFilter((prevState) => ({
        ...prevState,
        [Object.keys(params)[0]]: params,
      }));
    } else {
      addFilter((prevState) => ({
        ...prevState,
        [Object.keys(params)[0]]: Object.values(params)[0],
      }));
      setLastFilter(Object.keys(params)[0]);
    }
  };

  let buildQuery = () => {
    let query = {};
    for (let keys in filters) {
      if (
        filters[keys].constructor === Object ||
        (filters[keys].constructor === Array && filters[keys].length > 0)
      ) {
        query[keys] = filters[keys];
      }
    }
    return query;
  };

  let filterData = () => {
    let defineKeysWithMinMax = () => {
      let dateItemsNames = [];
      let dateItems = [];

      let filterDateItems = () => {
        return props.filterList.filter((item) => {
        
          return item.type === "date-start" || item.type === "date-end";
        });
      };

      let addNamesToArray = () => {
        return dateItems.map((i) => {
          return i.name;
        });
      };

      dateItems = filterDateItems();
      dateItemsNames = addNamesToArray();

      return dateItemsNames;
    };
    const keysWithMinMax = defineKeysWithMinMax();
    const filteredFiles = filesList.filter((file) => {
      for (let key in query) {
      
    
        if (file[key] === undefined) {
          return false;
        } else if (keysWithMinMax.includes(key)) {
       
          if (
            query[key][key]["min"] !== null &&
            new Date(file[key]) < query[key][key]["min"]
          ) {
            return false;
          }
          if (
            query[key][key]["max"] !== null &&
            new Date(file[key]) > query[key][key]["max"]
          ) {
            return false;
          }
        } else if (
          file[key].constructor === String &&
          !query[key].includes(file[key])
        ) {
          return false;
        } else if (file[key].constructor === Array) {
          if (!file[key].some((v) => query[key].includes(v))) {
            return false;
          }
        }
      }
      return true;
    });
    return filteredFiles;
  };

  let toogleView = () => {
    let component;

    if (view === "list" && !isFiltered) {
      component = (
        <div>
          <FilterElements
            dataset={props.dataset}
            filterList={props.filterList}
            name={props.name}
            filesList={filesList}
            filteredFiles={filteredFiles}
            aggregate={aggregateFilters}
            lastFilterUsed={lastFilterUsed}
            query={query}
            isFiltered={isFiltered}
          />
          <ProjectListView
            viewParams={props.viewParams}
            setView={handleSetView}
            navigateToList={navigateToList}
            isFiltered={isFiltered}
            isLoading={isLoading}
            numberOfFiles={numberOfFiles}
            stringArr={props.stringArr}
          />
        </div>
      );
    } else if (view === "table") {
      component = (
        <div>
          <FilterElements
            dataset={props.dataset}
            filterList={props.filterList}
            name={props.name}
            filesList={filesList}
            filteredFiles={filteredFiles}
            aggregate={aggregateFilters}
            lastFilterUsed={lastFilterUsed}
            query={query}
            isFiltered={isFiltered}
          />
          <ProjectTableView
            viewParams={props.viewParams}
            setView={handleSetView}
            navigateToList={navigateToList}
            filesList={filesList}
            isFiltered={isFiltered}
            filteredFiles={filteredFiles}
            metadata={props.metadata}
          />
        </div>
      );
    } else if (view === "map") {
      component = (
        <div>
          <FilterElements
            dataset={props.dataset}
            filterList={props.filterList}
            name={props.name}
            filesList={filesList}
            filteredFiles={filteredFiles}
            aggregate={aggregateFilters}
            lastFilterUsed={lastFilterUsed}
            query={query}
            isFiltered={isFiltered}
          />
          <ProjectMapView
            viewParams={props.viewParams}
            setView={handleSetView}
            navigateToList={navigateToList}
            filesList={filesList}
            isFiltered={isFiltered}
            filteredFiles={filteredFiles}
          />
        </div>
      );
    } else {
      component = (
        <div>
          <FilterElements
            dataset={props.dataset}
            filterList={props.filterList}
            name={props.name}
            filesList={filesList}
            filteredFiles={filteredFiles}
            aggregate={aggregateFilters}
            lastFilterUsed={lastFilterUsed}
            query={query}
            isFiltered={isFiltered}
          />
          <ProjectTableView
            viewParams={props.viewParams}
            setView={handleSetView}
            navigateToList={navigateToList}
            filesList={filesList}
            isFiltered={isFiltered}
            filteredFiles={filteredFiles}
            metadata={props.metadata}
          />
        </div>
      );
    }
    return component;
  };

  return toogleView();
}

export default ProjectView;
