import React from "react";
import Toogle from "./components/Toogle.js";
import ViewSettings from "./components/ViewSettings.js";
import CustomCSS from "./components/CustomCSS.js";
import Filters from "./components/Filters.js";
import Views from "./components/Views.js";
import Users from "./components/Users.js";
import NewUser from "./components/NewUser.js";
import EditUser from "./components/EditUser.js";
import Analytics from "./components/Analytics.js";
import Header from "../../components/header/Header";
import "./styles/styles.css";

export default class MainView extends React.Component {
  constructor(props) {
    super();
    this.state = {
      currentView: "Personalization",
      rm_user_id: "",
    };
  }

  defineView = (name) => {
    this.setState({ currentView: name });
  };

  defineId = (_id) => {
    this.setState({ rm_user_id: _id });
  };

  chooseWhichPanel = () => {
    switch (this.state.currentView) {
      case "Personalization":
        return <ViewSettings view="Personalization" />;
        break;
      case "Filters":
        return <Filters view="Filters" />;
        break;
      case "Custom CSS":
        return <CustomCSS view="CustomCSS" />;
        break;
      case "Users":
        return (
          <Users
            view="Users"
            defineView={this.defineView}
            defineId={this.defineId}
          />
        );
        break;
      case "NewUser":
        return <NewUser view="NewUser" />;
        break;
      case "EditUser":
        return <EditUser view="EditUser" _id={this.state.rm_user_id} />;
        break;
      case "Views":
        return <Views view="Views" />;
        break;
      case "Analytics":
        return <Analytics view="Analytics" />;
        break;
      default:
        return <ViewSettings view="Personalization" />;
    }
  };

  render() {
    return (
      <div>
        <Header />
        <div className="adminWrapper">
          <Toogle defineView={this.defineView} />
          {this.chooseWhichPanel()}
        </div>
      </div>
    );
  }
}
