import React from "react";
import "./mainBody.css";

import axios from "axios";
import Utils from "../../../utils";
class MainBody extends React.Component {
  constructor(props) {
    super();
    this.state = {
      storage: false,
      params: [
        { params: { view: "Personalization" } },
        { params: { view: "Views" } },
      ],
      item: {
        Info: [
          { name: "", text: "" },
          { name: "", text: "" },
          { name: "", text: "" },
          { name: "", text: "" },
        ],
      },
    };
  }

  componentDidMount() {
    Utils.loadSettings("main").then((value) => {
    
      this.setState({ item: JSON.parse(value) });
  
    });
  }

  render() {
    //
    return (
      <div className="landingMainBody">
        <div
          className="landingTitle"
          style={{ backgroundImage: "url(/images/background.jpg)" }}
        >
          <span id="welcomeMessage">Welcome to {this.state.item.Code}</span>
          <span id="pageTitle">{this.state.item.Title}</span>

          <a id="tryButton" href={"/main"}>
            <div className="tryButton">
              <span id="tryButtonMessage">
                TRY {this.state.item.Code} PLATFORM
              </span>
            </div>
          </a>
        </div>

        <div className="informationBlockWrapper">
          <h2>Why is it worth using our Database?</h2>
          <div className="informationBlock">
            <div className="informationBlockElement block1">
              <h3>{this.state.item.Info[0].name}</h3>
              <p>{this.state.item.Info[0].text}</p>
            </div>
            <div className="informationBlockElement block2">
              <h3>{this.state.item.Info[1].name}</h3>
              <p>{this.state.item.Info[1].text}</p>
            </div>
            <div className="informationBlockElement block3">
              <h3>{this.state.item.Info[2].name}</h3>
              <p>{this.state.item.Info[2].text}</p>
            </div>
            <div className="informationBlockElement block4">
              <h3>{this.state.item.Info[3].name}</h3>
              <p>{this.state.item.Info[3].text}</p>
            </div>
          </div>
        </div>
        <div className="footer">
          <p className="footerText">
            Copyright © EPOS-pl 2016-2021 | Dariusz Wójcik
          </p>
        </div>
      </div>
    );
  }
}

export default MainBody;
