import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { config } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-date-picker";
import FilterParamsItem from "./FilterParamsItem";

class FilterParams extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: [],
      status: [],
      newDataset: [],
      active: true,
      date: "",
      unique: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  

  handleChange(event) {
    let item = event.target.value;
    if (event.target.checked === true) {
      this.props.filterContent({
        [this.props.name]: event.target.value,
        status: true,
      });

      this.setState((prevState) => ({
        filter: [...prevState.filter, item],
      }));
    } else if (event.target.defaultChecked === true) {
      this.props.filterContent({
        [this.props.name]: event.target.value,
        status: false,
      });

      let index = this.state.filter.indexOf(item);
      if (index !== -1) {
        let filterItems = [...this.state.filter];
        filterItems.splice(index, 1);
        this.setState((prevState) => ({
          filter: filterItems,
        }));
      }
    } else if (event.target.checked === false) {
      this.props.filterContent({
        [this.props.name]: event.target.value,
        status: false,
      });

      let index = this.state.filter.indexOf(item);
      if (index !== -1) {
        let filterItems = [...this.state.filter];
        filterItems.splice(index, 1);
        this.setState((prevState) => ({
          filter: filterItems,
        }));
      }
    }
  }

  handleDateChange = (data) => {
    this.props.filterContent(data);
  };

  handleSubmit(event) {
    event.stopPropagation();
  }

  handleToogleClick = () => {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
  };

  isMultiple = () => {
    return this.props.multiple;
  };

  checkIfActive = (arrItem) => {
    const isActive = this.state.filter.indexOf(arrItem) !== -1;
    return isActive;
  };

  helperFunction = (element, index) => {
    let checked = this.checkIfActive(element);

    return (
      <FilterParamsItem
        name={element}
        parentElement={this.props.displayName}
        index={index}
        isChecked={checked}
        onClick={this.handleChange}
      />
    );
  };

  render() {
    config.autoAddCss = false
    let unique;
    if (this.props.type === "date-start") {
      unique = (
        <DatePicker
          selected={this.state.date}
          value={this.state.date}
          onChange={(date) =>
            this.setState(
              { date },
              this.handleDateChange({
                start: {
                  min: date,
                  max: null,
                },
                status: "date-start",
              })
            )
          }
        />
      );
    } else if (this.props.type === "date-end") {
      unique = (
        <DatePicker
          selected={this.state.date}
          value={this.state.date}
          onChange={(date) =>
            this.setState(
              { date },
              this.handleDateChange({
                end: {
                  min: null,
                  max: date,
                },
                status: "date-end",
              })
            )
          }
        />
      );
    } else if (this.props.type === "multiple values") {
      let selectUniqueValues = () => {
        const unique = [];
        let a;

        if (this.props.filteredData) {
          if (Object.keys(this.props.filteredData).length > 0) {
            a = this.props.filteredData;
          } else {
            a = this.props.dataset;
          }
        } else {
          a = this.props.dataset;
        }
        ///
        a.map((details) => {
          let selector = this.props.name;
       
          if (details[selector] && details[selector].constructor === Array) {
            details[selector].map((item) => {
              if (unique.indexOf(item) === -1) {
                unique.push(item);
              }
            });
          }
      });
        return unique;
      };

      this.props.dataset.map(() => {
        unique = selectUniqueValues()
          .sort()
          .map((element) =>  this.helperFunction(element));
      });
    } else {
      let selectUniqueValues = () => {
        const unique = [];
        let a;

        if (this.props.filteredData) {
          if (Object.keys(this.props.filteredData).length > 0) {
            a = this.props.filteredData;
          } else {
            a = this.props.dataset;
          }
        } else {
          a = this.props.dataset;
        }
   
        a.map((details) => {
          let selector = this.props.name;
     
          
          if (details[selector] && details[selector].constructor === String) {

            // Added string fields which can't be arrays in DC
            if (selector === "country" || selector === "region") {
             
              let newArr = details[selector].split(',').map((item) => item.trim())
              newArr.map((item) => {
                if (unique.indexOf(item) === -1) {
                  unique.push(item);
                }
              })
            }

            
            if ((selector !== "country" && selector !== "region") && unique.indexOf(details[selector]) === -1) {
              unique.push(details[selector]);
            }
          }
        });
        return unique;
      };

      unique = selectUniqueValues()
        .sort()
        .map((element, index) => this.helperFunction(element, index));
    }

    return (
      <div className="FilterParamsWrapper">
        {/* Here will be parsed props with value. Currently only for layout added country. */}
        <div className="FilterParamsHeader">
          <div className="FilterCategory" key={this.props.displayName}>
            {this.props.displayName}
            <div className="Chevron">
              <FontAwesomeIcon
                icon={faChevronDown}
                onClick={this.handleToogleClick}
              />
            </div>
          </div>
        </div>
        {/* List of values will be parsed with props and will be pased on values in
        a query fields. All values will also be available. Values may dissapear
        when certain value is no longer applicable (shows only what queried
        metadata have); */}
        <form className={"FilterChoices " + (this.state.active && "active")}>
          {unique}
        </form>
      </div>
    );
  }
}

export default FilterParams;
