import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
//import { isLogin } from "../utils";
import Utils from "../utils/index";
import UserService from "../services/user.service";
import AuthService from "../services/auth.service";
import Admin from "../scenes/admin/index.js";

const PrivateRoute = ({ component: Component, restricted, ...rest }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const fetchData = () => {
    //you need to add try catch here
    UserService.isLogin()
      .then((res) => {
        if (res.status === 200 ) {
       
          setIsAuth(true);
          setLoaded(true);
        }
      })
      .catch((err) => {
        console.log(err)
        if (err) {
          setIsAuth(false);
          setLoaded(true);
          AuthService.logout();
        } else {
          setLoaded(false);
          return;
        }
      });
  };

  const logoutAndRedirect = () => {
    AuthService.logout();
    return <Redirect to="/login" />;
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    loaded ? (
      restricted === false ? (
        <Route
          {...rest}
          render={(props) =>
            isAuth ? <Component {...props} /> : logoutAndRedirect()
          }
        />
      ) : (
        <Route
          {...rest}
          render={(props) =>
            isAuth && Utils.isAdmin() ? (
              <Component {...props} />
            ) : (
              logoutAndRedirect()
            )
          }
        />
      )
    ) : // ) : (
    //   <div>
    //     <p>Sorry for inconvinience. </p>
    //     <p>CIB-Platform is unavailable right now.</p>
    //     <p>Please come back later"</p>
    //   </div>
    // )
    null
  );
};

export default PrivateRoute;
