import React, { useState, useEffect } from "react";
import "../styles/ViewSettings.css";
import "../styles/Users.css";
import UserService from "../../../services/user.service";

function Users(props) {
  const [users, setUsers] = useState([]);

  const AddNewUser = () => {
    props.defineView("NewUser");
  };
  const EditUser = (event) => {
    props.defineView("EditUser");
    props.defineId(event.target.id);
  };

  const listAllUsers = () => {
    let tmpValue = [];
    UserService.getAdminUsers().then((value) => {
      value.data.map((i) => {
        tmpValue.push(i);
      });
      setUsers(tmpValue);
    });
  };

  const makeTable = () => {
    return users.map((i) => {
      return (
        <tr id={i}>
          <td>{i.username}</td>
          <td>{i.email}</td>
          <td>{i.affiliation}</td>
          <td>{i.groups}</td>
          <td>
            <span id={i._id} className="editUserLink" onClick={EditUser}>
              EDIT
            </span>
          </td>
        </tr>
      );
    });
  };

  useEffect(() => {
    listAllUsers();
  }, []);

  return (
    <div className="ViewSettings">
      <h3>Users</h3>
      <div className="userPanelWrapper">
        <div className="addNewButton" onClick={AddNewUser}>
          Add new
        </div>
        <table className="usersTable">
          <tr>
            <td>Name</td>
            <td>e-mail</td>
            <td>Afiliation</td>
            <td>Groups</td>
            <td></td>
          </tr>
          {makeTable()}
        </table>
      </div>
    </div>
  );
}

export default Users;
