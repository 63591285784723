import React, { useState, useEffect } from "react";
import "../styles/ViewSettings.css";
import { configs } from"../../../config";
import axios from "axios";

function ViewSettings(props) {
  const [data, setData] = useState({
    FormType: "personalization",
    Title: "",
    Code: "",
    Info: [
      { name: "", text: "" },
      { name: "", text: "" },
      { name: "", text: "" },
      { name: "", text: "" },
    ],
  });
  const [saveStatus, setSaveStatus] = useState(false);
  const [changed, setChanged] = useState(false);

  const form = false;

  //Retrive API data when settings page is changed.
  useEffect(() => {
    let query = { params: { view: props.view } };

    axios
      .get(
        configs.PROTOCOL + configs.DOMAIN + configs.DATA_PORT + "/api/settings",
        query
      )
      .then((res) => {
        setData(res.data);
      });

    setSaveStatus(false);
    setChanged(false);
  }, [props.view]);

  //Function for updating component state while changing input fields
  let changeState = (event) => {
    setSaveStatus(false);

    if (event.target.id === "") {
      setData({ ...data, [event.target.name]: event.target.value });
    } else {
      let char = event.target.id.charAt(1);
      let field = "";
      char === "a" ? (field = "name") : (field = "text");

      let index1 = Number(event.target.id.charAt(0));
      let Info = data["Info"];
      Info.map((item, index2) => {
        if (index2 === index1) {
          Info[index2][field] = event.target.value;
        }
        return null;
      });
      setData({ ...data, Info: Info });
    }

    setChanged(true);
  };

  let save = () => {
    axios
      .post(
        configs.PROTOCOL + configs.DOMAIN + configs.DATA_PORT + "/api/settings",
        data
      )
      .then((res) => {});
    setSaveStatus(true);
    setChanged(false);
  };

  //Create form using all retrived data.
  let createForm = () => {
    let dataKeys = Object.getOwnPropertyNames(data);

    return dataKeys.map((item) => {
      if (item !== "FormType") {
        if (form === false) {
          if (typeof data[item] === "object") {
            return data[item].map((item, index) => {
              return (
                <div className="settingsFormWrapperInfo">
                  <div>
                    <label for="fname">Description number {index + 1}</label>
                  </div>
                  <div>
                    <input
                      type="text"
                      name="fname"
                      id={index + "a"}
                      defaultValue={item.name}
                      size="70"
                      onChange={changeState}
                    ></input>
                    <br />
                    <textarea
                      name="fname"
                      id={index + "b"}
                      defaultValue={item.text}
                      size="70"
                      onChange={changeState}
                    ></textarea>
                  </div>
                </div>
              );
            });
          } else {
            if (item !== "") {
              return (
                <div className="settingsFormWrapper">
                  <div>
                    <label for={item}>{item}</label>
                  </div>
                  <div>
                    <input
                      type="text"
                      name={item}
                      defaultValue={data[item]}
                      size="70"
                      onChange={changeState}
                    ></input>
                  </div>
                </div>
              );
            }
          }
        }
      }
      return null;
    });
  };

  return (
    <div className="ViewSettings">
      <h3>{props.view}</h3>
      <div className="SaveButton">
        {changed ? <button onClick={save}>Save</button> : null}
        {saveStatus ? <span>Saved successfully!</span> : null}
      </div>
      {createForm()}
    </div>
  );
}

export default ViewSettings;
