import React, { useState, useEffect } from "react";
import "../styles/Filters.css";
import axios from "axios";
import { configs } from"../../../config";

function Filters(props) {
  const [data, setData] = useState("");
  const [ifChild, addChild] = useState(false);
  const [selected, select] = useState([]);
  const [isChecked, check] = useState();

  useEffect(() => {
    let query = { params: { view: props.view } };

    axios
      .get(
        configs.PROTOCOL + configs.DOMAIN + configs.DATA_PORT + "/api/settings",
        query
      )
      .then((res) => {
        setData(res.data);
      });
  }, [props.view]);

  let save = (inputData) => {
    //Add new filter data to state
    if (inputData) {
      let FilterList = data.FilterList;
      FilterList.push(inputData);
      setData({ ...data, FilterList });
    }

    //Send POST request with new filter list
    axios
      .post(
        configs.PROTOCOL + configs.DOMAIN + configs.DATA_PORT + "/api/settings",
        data
      )
      .then((res) => {});
    //Remove input form from DOM;
    addChild(false);
  };

  let addNewFilter = () => {
    let list = { type: "single values", level: "main" };

    let merge = (event) => {
      if (
        event.target.id === "save" &&
        Object.getOwnPropertyNames(list).length === 4
      ) {
        save(list);
      }
      if (event.target.id !== "save") {
        list[event.target.id] = event.target.value;
      }
    };

    if (ifChild !== false) {
      return (
        <tr>
          <td>
            <input type="checkbox" id="selectNew"></input>
          </td>
          <td>
            <input type="text" id="displayName" onChange={merge}></input>
          </td>
          <td>
            <input type="text" id="name" onChange={merge}></input>
          </td>
          <td>
            <select id="type" onChange={merge}>
              <option>single values</option>
              <option>multiple values</option>
              <option>date-start</option>
              <option>date-end</option>
            </select>
          </td>
          <td>
            <select id="level" onChange={merge}>
              <option>main</option>
              <option>data-set</option>
            </select>
          </td>
          <td className="saveLink" id="save" onClick={merge}>
            Save
          </td>
        </tr>
      );
    }
  };

  let populateTable = () => {
    let dataKeys = Object.getOwnPropertyNames(data);

    let removeItem = (event) => {
      const index = Number(event.target.id.substring(6));

      let FilterList = data.FilterList;
      FilterList.splice(index, 1);
      setData({ ...data, FilterList });
      save();
    };

    return dataKeys.map((item) => {
      if (item !== "FormType") {
        if (typeof data[item] === "object") {
          return data[item].map((item, index) => {
            return (
              <tr>
                <td>
                  <input
                    id={index}
                    type="checkbox"
                    checked={isChecked}
                    onClick={selectForRemove}
                  ></input>
                </td>
                <td>{item.displayName}</td>
                <td>{item.name}</td>
                <td>{item.type}</td>
                <td>{item.level}</td>
                <td>
                  <span
                    className="actionLinks"
                    id={"delete" + index}
                    onClick={removeItem}
                  >
                    Usuń
                  </span>
                </td>
              </tr>
            );
          });
        }
      } else {
        return null;
      }
      return null;
    });
  };

  let selectForRemove = (event) => {
    let checkedList = selected;

  
    if (event.target.checked) {
      checkedList.push(Number(event.target.id));
      select(checkedList);
    } else {
      const index = checkedList.findIndex(
        (element) => element === event.target.id
      );
      checkedList.splice(index, 1);
      select(checkedList);
    }
  };

  let removeSelected = () => {
    let FilterList = data.FilterList;

    //Sort list descending to remove from the last element
    select(
      selected.sort(function compareNumbers(a, b) {
        return b - a;
      })
    );

    selected.map((itemIndex) => {
      return FilterList.splice(itemIndex, 1);
    });
    setData({ ...data, FilterList });
    select([]);
    check(false);
    save();
  };

  return (
    <div className="customFilterWrapper">
      <h3>Filters</h3>
      <p>Below you can add filters based on your own metadata:</p>
      <div className="addNewFilterButton" onClick={addChild}>
        Add new
      </div>
      <div className="removeSelectedFiltersButton" onClick={removeSelected}>
        Remove selected
      </div>
      <table className="filtersTable">
        <thead className="tableHead">
          <th>
            <input type="checkbox" id="selectAll"></input>
          </th>
          <th>Filter name</th>
          <th>Attribute</th>
          <th>Filter type</th>
          <th>Level</th>
          <th>Action</th>
        </thead>
        {populateTable()}
        {addNewFilter()}
      </table>
    </div>
  );
}

export default Filters;
