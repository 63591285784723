import React from "react";
import Header from "../../components/header/Header";
import Account from "./components/account";

function User(props) {
  return (
    <div className="User">
      <Header />
      <Account />
    </div>
  );
}

export default User;
