import React from "react";
import "./styles/Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { config } from '@fortawesome/fontawesome-svg-core';
import { faSignInAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import Utils from "../../utils";

//Add css property to a body element
const homePageLayout = "homePageLayout";

class Header extends React.Component {
  constructor(props) {
    super();
    this.state = {
      isVisible: "hidden",
      item: {}
    };
  }

  componentDidMount() {
    document.body.classList.add(homePageLayout);
    config.autoAddCss = false

    Utils.loadSettings("main").then((value) => {
      this.setState({ item: JSON.parse(value) });
    });
  }

  render() {
  
    let logOut = () => {
      localStorage.removeItem("user");
      localStorage.removeItem("user");
      window.location.href = "/";
    };

    //Temporary mocking function for switching loginStatus in the header//
    let ToogleState = () => {
      let status = JSON.parse(localStorage.getItem("user"));

      let showUserOptions = (event) => {
      
        event.type === "mouseenter"
          ? this.setState({ isVisible: "" })
          : this.setState({ isVisible: "hidden" });
      };

      if (status) {
        return (
          <div onMouseEnter={showUserOptions} onMouseLeave={showUserOptions}>
            <a href="/user">
              <FontAwesomeIcon icon={faUser} />
              {" " + status.email}
            </a>
            <p id="logOut" onClick={logOut} className={this.state.isVisible}>
              Log out
            </p>
          </div>
        );
      } else {
        return (
          <a href="/login">
            <FontAwesomeIcon icon={faSignInAlt} /> Log in
          </a>
        );
      }
    };

    return (
      <div className="headerWrapper">
        <div className="containerFluid">
          <div className="logoBlock">
            <a href="/">
              <img src="../images/logo.png" alt="logo" className="logoImage" />
            </a>
          </div>
          <div className="headerMidBlock">
            <div className="homePageHeadline">
              <p>{this.state.item.Code}</p>
            </div>
            <div className="homePageSlogan">
              {this.state.item.Title}
            </div>
          </div>
          <div className="headerLoginStatus">{ToogleState()}</div>
        </div>
      </div>
    );
  }
}
export default Header;
