import React from "react";
import "./button.css";

class Button extends React.Component {
  render() {
    return (
      <a href={this.props.href} className={this.props.cssClass}>
        {this.props.name}
      </a>
    );
  }
}

export default Button;
