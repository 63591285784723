import React, { useState, useRef } from "react";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import UserService from "../services/user.service";

function Password() {
  const form = useRef();
  const checkBtn = useRef();

  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const onChangePassword = (e) => {
    setMessage("");
    setLoading(false);
    e.target.id === "password"
      ? setPassword(e.target.value)
      : setPasswordRepeat(e.target.value);
  };

  const checkIfIdentical = () => {
    return password === passwordRepeat ? true : false;
  };

  const checkLength = () => {
    return password.length >= 8 ? true : false;
  };

  const getUserId = () => {
    const _id = JSON.parse(localStorage.getItem("user")).id;
 
    return _id;
  };

  const handlePasswordUpdate = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();
   
    if (
      checkBtn.current.context._errors.length === 0 &&
      checkIfIdentical() &&
      checkLength()
    ) {
      UserService.updatePassword(getUserId(), password).then(
        () => {
          window.location.href = "/user";
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          setMessage(resMessage);
        }
      );
    }
    if (!checkIfIdentical()) {
      setMessage("Passwords are different!");
      setLoading(false);
    }
    if (!checkLength()) {
      setMessage("New password is too short!");
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="wrapper">
      <div className="center">
        <div className="logoBlock">
          <div className="imageWrapper">
            <a href="/">
              <img src="../images/logo.png" alt="logo" className="logoImage" />
            </a>

            <Form
              onSubmit={handlePasswordUpdate}
              className="formField"
              ref={form}
            >
              <input
                type="password"
                className="inputField"
                id="password"
                onInvalidCapture="password"
                placeholder="NEW PASSWORD"
                value={password}
                onChange={onChangePassword}
              />
              <input
                type="password"
                className="inputField"
                id="password_repeat"
                placeholder="REPEAT PASSWORD"
                value={passwordRepeat}
                onChange={onChangePassword}
              />
              <button className="button" disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>UPDATE</span>
              </button>
              {message && (
                <div className="form-alert">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
              <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Password;
