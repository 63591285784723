import React from "react";
import "../styles/MainWrapper.css";
import Utils from "../../../utils";
import FilterBlock from "./FilterBlock";

import QueryResults from "./QueryResults";
import ProjectView from "./ProjectView";

import MapView from "./MapView";
class MainWrapper extends React.Component {
  constructor(props) {
    super();
    this.state = {
      filterParameters: [],
      currentView: "QueryResults",
      viewParams: [],
      filteredData: {},
      loading: true,
    };

    this.updatedDataset = this.updatedDataset.bind(this);
  }

  componentDidMount() {
    if (this.props.dataset.length === 0) {
      this.setState({ loading: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dataset !== this.props.dataset) {
      this.updateAndNotify();
    }
  }

  updateAndNotify = () => {
    if (this.props.dataset.length !== 0) {
      this.setState({ loading: false });
    }
  };

  createFilter = () => {
    if (!this.state.filter) {
      let filter = {};

      this.props.filterList.map((obj) => {
        return (filter[obj.name] = []);
      });

      this.setState({ filter });
    }
  };

  buildQuery = (status) => {
    let query = {};
    let filter = this.state.filter;
    for (let keys in filter) {
      if (
        filter[keys].constructor === Object ||
        (filter[keys].constructor === Array && filter[keys].length > 0)
      ) {
        query[keys] = filter[keys];
      }
    }
    return query;
  };

  filterData = () => {
    let defineKeysWithMinMax = () => {
      let dateItemsNames = [];
      let dateItems = [];

      let filterDateItems = () => {
        return this.props.filterList.filter((item) => {
          return item.type === "date-start" || item.type === "date-end";
        });
      };

      let addNamesToArray = () => {
        return dateItems.map((i) => {
          return i.name;
        });
      };

      dateItems = filterDateItems();
      dateItemsNames = addNamesToArray();

      return dateItemsNames;
    };
    const keysWithMinMax = defineKeysWithMinMax();

    const filteredData = this.props.dataset.filter((item) => {
      for (let key in this.state.query) {
     
        if (item[key] === undefined) {
          return false;
        } else if (keysWithMinMax.includes(key)) {
          if (
            this.state.query[key][key]["min"] !== null &&
            new Date(item[key]) < this.state.query[key][key]["min"]
          ) {
            return false;
          }
          if (
            this.state.query[key][key]["max"] !== null &&
            new Date(item[key]) > this.state.query[key][key]["max"]
          ) {
            return false;
          }
        } else if (
          item[key].constructor === String &&
          // !this.state.query[key].includes(item[key])
          this.state.query[key].filter((e) => {
            return item[key].includes(e)   
          }).length===0
       
        ) {
        
          return false;
          // } else if (item[key].constructor === Array) {
          //   if (!item[key].every((v) => this.state.query[key].includes(v))) {
          //     return false;
          //   }
          // }
        } else if (item[key].constructor === Array) {
          if (!item[key].some((v) => this.state.query[key].includes(v))) {
            return false;
          }
        }
      }
      return true;
    });
    return filteredData;
  };

  filterContent = (params) => {
    //This function is passed as a callback to c.FilterBlock
    //and then once called define the new results list for display in c.QueryResults
    let filterParameters = [...this.state.filterParameters];

    //If "filter" state does not exist create one with keys for every filter
    //Run only
    this.createFilter();

    if (
      params.status === true &&
      params.status !== "date-start" &&
      params.status !== "date-end"
    ) {
      this.setState(
        (prevState) => ({
          filter: {
            ...prevState.filter,
            [Object.keys(params)[0]]: [
              ...prevState.filter[Object.keys(params)[0]],
              Object.values(params)[0],
            ],
          },
        }),
        function () {
          this.setState({ query: this.buildQuery() }, function () {
            this.setState({ filteredData: this.filterData() });
          });
        }
      );
    } else if (params.status === "date-start" || params.status === "date-end") {
      this.setState(
        (prevState) => ({
          filter: {
            ...prevState.filter,
            [Object.keys(params)[0]]: params,
          },
        }),
        function () {
          this.setState({ query: this.buildQuery() }, function () {
            this.setState({ filteredData: this.filterData() });
          });
        }
      );
    } else {
      let index = this.state.filter[Object.keys(params)[0]].indexOf(
        Object.values(params)[0]
      );
      let filterKey = [...this.state.filter[Object.keys(params)[0]]];

      filterKey.splice(index, 1);

      this.setState(
        (prevState) => ({
          filter: {
            ...prevState.filter,
            [Object.keys(params)[0]]: filterKey,
          },
        }),
        function () {
          this.setState({ query: this.buildQuery() }, function () {
            this.setState({ filteredData: this.filterData() });
          });
        }
      );
    }

    this.filterData();

    filterParameters.push(params);
    const key = Object.keys(params)[0];

    function getOccurrence() {
      let query = filterParameters.filter((item) => item[key] === (params[key]));
      if (query.length === 2) {
        return query[0][key];
      } else {
        return null;
      }
    }

    function findIndex(query) {
      let indexes = [];
      filterParameters.map((data, index) => {
        if (data[key] === query) {
          return indexes.push(index);
        } else {
          return null;
        }
      });
      return indexes;
    }

    if (getOccurrence() !== null) {
      let value = getOccurrence();
      let index = findIndex(value);
      filterParameters.splice(index[0], 1);
      filterParameters.splice(index[1] - 1, 1);
    }

    //Check if passed params objects exist event.target.value
    this.setState({ filterParameters }, () => this.updatedDataset());
  };

  updatedDataset = () => {
    let filterParameters = [...this.state.filterParameters];

    if (filterParameters.length === 0) {
      this.setState({ filteredDetails: undefined });
    } else {
      let filteredDetails = [];
      let currentKey, currentValue;

      Object.values(filterParameters).map((filter) => {
    
        currentKey = Object.keys(filter)[0];
        currentValue = Object.values(filter)[0];
        return this.props.dataset.map((item) => {
        
          if (!item[currentKey] === "end" && item[currentKey].includes(currentValue)) {
           
            return filteredDetails.push(item);
          }
          if (item[currentKey] === "end" && item[currentKey] === currentValue) {
            return filteredDetails.push(item);
          } else {
            return null;
          }

         
        });
      });
      filteredDetails = filteredDetails.sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
      this.setState({ filteredDetails }, () =>
     {}
      );
    }
  };

  determineViewParams = (props) => {
    //Determine id and ProjectView for QueryResults click on "Show dataset" button
    //Determine map view
   
    if (props === "MapView") {
      this.setState({ currentView: "MapView" });
    } else {
      const path = "/main/" + props.name;
      window.history.pushState("", "", path);

      this.setState({ viewParams: props });
      this.setState({ currentView: "ProjectView" });

      // const url = 'projectView'
      window.scroll(0, 0);
      // window.history.pushState('','', url);
      window.history.go(1);

      // let changeCurrentView = () => {
      //   if (window.location.href === "http://http://localhost/main") {
      //     this.setState({ currentView: "QueryResults" });
      //   }
      // };
      // window.onpopstate = function () {
      //   changeCurrentView();
      // };
    }
  };

  goBackToMain = () => {
    this.setState({ currentView: "QueryResults" });

    const params = {};
    this.setState({ viewParams: params });
    //window.history.replaceState("", "", "/main");
    // if (this.props.match.params.project) {
    //   window.location.href = "/main";
    // } else {
    const path = "/main";

    return window.history.pushState("", "", path);
    // }
    // document.location.href = "/main";
    //window.history.pushState("", "", "/main");
  };

  render() {
    let ViewOptions = () => {
      if (this.state.currentView === "QueryResults") {
        return (
          <div>
            <FilterBlock
              dataset={this.props.dataset}
              filterList={this.props.filterList}
              filterContent={this.filterContent}
              filteredDetails={this.state.filteredDetails}
              filteredData={this.state.filteredData}
              filterParameters={this.state.filterParameters}
              currentView={this.state.currentView}
              query={this.state.query}
            />
            <QueryResults
              metadata={this.props.metadata}
              dataset={this.props.dataset}
              filteredDetails={this.state.filteredDetails}
              filteredData={this.state.filteredData}
              filterParameters={this.state.filterParameters}
              determineViewParams={this.determineViewParams}
              query={this.state.query}
              match={this.props.match}
              goBackToMain={this.goBackToMain}
              loading={this.state.loading}
            />
          </div>
        );
      } else if (this.state.currentView === "ProjectView") {
        return (
          <div>
            <ProjectView
              viewParams={this.state.viewParams}
              dataset={this.props.dataset}
              goBackToMain={this.goBackToMain}
              filterList={this.props.filterList}
              name={this.state.viewParams.name}
              metadata={this.props.metadata}
              ip={this.props.ip}
              stringArr={this.props.stringArr}
            />
          </div>
        );
      } else if (this.state.currentView === "MapView") {
        return (
          <div>
            <FilterBlock
              dataset={this.props.dataset}
              filterList={this.props.filterList}
              filterContent={this.filterContent}
              currentView={this.state.currentView}
              filteredDetails={this.state.filteredDetails}
              filteredData={this.state.filteredData}
              filterParameters={this.state.filterParameters}
              query={this.state.query}
            />
            <MapView
              viewParams={this.state.viewParams}
              goBackToMain={this.goBackToMain}
              query={this.state.query}
              dataset={this.props.dataset}
              filteredData={this.state.filteredData}
              filterParameters={this.state.filterParameters}
            />
          </div>
        );
      }
    };

    return <div>{ViewOptions()}</div>;
  }
}

export default MainWrapper;
