import React, { useState, useEffect } from "react";
import "../styles/account.css";
import UserService from "../../../services/user.service";
import AuthService from "../../../services/auth.service";

function UserData(props) {
  const id = AuthService.getCurrentUserId();
  const role = AuthService.getCurrentUserRole();

  const [data, setData] = useState([
    { username: "", email: "", affiliation: "", groups: "" },
  ]);

  function handleChangePassword() {
    window.location.href = "/password";
  }

  function handleLogOut() {
   
      localStorage.removeItem("user");
      localStorage.removeItem("user");
      window.location.href = "/";
    
  }

  function getUserDetails() {
    if (role === "ROLE_ADMIN") {
      UserService.getAdminBoard(id)
        .then((i) => {
          setData(i.data);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            AuthService.logout();
            window.location.href = "/login";
          }
        });
    } else {
      UserService.getUserBoard(id)
        .then((i) => {
          setData(i.data);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            AuthService.logout();
            window.location.href = "/login";
          }
        });
    }
  }

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <div className="DataDashboard">
      <p>
        <span>Username :</span> {data[0].username}
      </p>
      <p>
        <span>Email :</span> {data[0].email}
      </p>
      <p>
        <span>Afiliation :</span> {data[0].affiliation}
      </p>
      <p>
        <span>Groups :</span> {data[0].groups}
      </p>
      <button onClick={handleChangePassword} className="userDataButton">Change password</button>
      <button onClick={handleLogOut} className="userDataButton">Log out</button>
    </div>
  );
}

export default UserData;
