import AuthService from "../services/auth.service";
import { configs } from"../config";
import axios from "axios";

const TOKEN_KEY = "user";

const loadSettings = (level) =>



  new Promise((resolve, reject) => {


    let params = [
      { params: { view: "Personalization" } },
      { params: { view: "Views" } },
    ];

   params.map((q, index) => {
      const query = q;
      axios
        .get(
          configs.PROTOCOL + configs.DOMAIN + configs.DATA_PORT + "/api/settings",
          query
        )
        .then((res) => {
          let configData = res.data;
          let dataKeys = Object.getOwnPropertyNames(configData);
          let localVariables = localStorage.getItem("variables" + index);
          localVariables = localVariables ? JSON.parse(localVariables) : {};
       
          // Add new data to localStorage Array

          dataKeys.map((item, index) => {
            if (index >= 1) {
              return (localVariables[item] = configData[item]);
            } else {
              return;
            }
          });

        
          // Save back to localStorage
          // localStorage.setItem(
          //   "variables" + index,
          //   JSON.stringify(localVariables)
          // );
         
          if (level === "main" && index===0) {

            resolve(JSON.stringify(localVariables) )
          }

          if (level === "project" && index===1) {

            resolve(JSON.stringify(localVariables) )
          }


          return;
     
        });
    })
    
   ;
  });

const isLogin = () => {
  if (localStorage.getItem(TOKEN_KEY)) {
    return true;
  }

  return false;
};

const isAdmin = () => {
  return isLogin()
    ? JSON.parse(localStorage.getItem(TOKEN_KEY)).roles.includes("ROLE_ADMIN")
    : false;
};

export default { isLogin, isAdmin, loadSettings };
