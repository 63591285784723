import React, { useState, useEffect } from "react";
import "../styles/ViewSettings.css";
import "../styles/Users.css";
import AuthService from "../../../services/auth.service";

function NewUsers() {
  const [data, modifyData] = useState();
  const [msg, setMsg] = useState();

  const modifyState = (event) => {
    if (event.target.name === "groups") {
      // To developed adding multiplel groups
      modifyData({ ...data, groups: [event.target.value] });
    } else {
      modifyData({ ...data, [event.target.name]: event.target.value });
    }
  };

  const registerUser = () => {
    AuthService.register(
      data.username,
      data.email,
      data.password,
      data.affiliation,
      data.groups
    ).then(
      (response) => {
     
        if (response.status === 200) {
          setMsg(response.data.message);
        }
      }).catch(function (error) {
        if (error.response) {
          if (error.response.data.error) {
            setMsg(error.response.data.error);
          } else {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
          setMsg("Error occured when creating user");
          }
       
        }
    });
 
  };

  return (
    <div className="ViewSettings">
      <h3>Add New User:</h3>
      <form class="NewUserForm">
        <table onChange={modifyState}>
          <tr>
            <td>
              <label for="username">Name:</label>
            </td>
            <td>
              <input id="username" type="text" name="username" />
            </td>
          </tr>
          <tr>
            <td>
              <label for="password">Password:</label>
            </td>
            <td>
              <input type="password" name="password" />
            </td>
          </tr>
          <tr>
            <td>
              <label for="email">email:</label>
            </td>
            <td>
              <input type="e-mail" name="email"></input>
            </td>
          </tr>
          <tr>
            <td>
              <label for="affiliation">Afiliation</label>
            </td>
            <td>
              <input type="text" name="affiliation"></input>
            </td>
          </tr>
          <tr>
            <td>
              <label for="groups">Groups</label>
            </td>
            <td>
              <input type="text" name="groups"></input>
            </td>
          </tr>
        </table>
        <div className="saveLink" id="save" onClick={registerUser}>
          Save
        </div>
        <div>
          <p>{msg}</p>
        </div>
      </form>
    </div>
  );
}

export default NewUsers;
