import React, { useState, useEffect } from "react";
import "../styles/Analytics.css";
import axios from "axios";
import DataSerice from "../../../services/data.service";


function Analytics(props) {
  const [totalAmount, setTotalAmount] = useState("");
  const [count,setCount] = useState("");

  const getStats = () => {
    DataSerice.getStats().then((item) => {setTotalAmount(item.data.totalSize.toFixed(3)); setCount(item.data.count)})
  }

  useEffect(() => {
    getStats()
  }, []);


  return (
    <div className="ViewSettings">
      {" "}
      <h3>Analytics</h3>
      <div className="AnalyticsWrapper">
        <div>Transfer in Total: {totalAmount} MB</div>
        <div>Downloaded {count} times</div>
      </div>
    </div>
  );
}

export default Analytics;
