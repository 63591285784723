import React, { useState, useEffect, useReducer } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { config } from '@fortawesome/fontawesome-svg-core';

import "../styles/FilterDataset.css";

function FilterDatasetItem(props) {
  
  config.autoAddCss = false
  let handleClick = (event) => {
    props.createFilterList(event);
  };

  return (
    <div className={"FilterChoices active"} onChange={handleClick}>
      <input
        type="checkbox"
        value={props.name}
        name={props.name}
        id={props.name}
        checked={props.isChecked}
      />
      <label htmlFor={props.name}>{"  " + props.name}</label>
    </div>
  );
}

export default FilterDatasetItem;
