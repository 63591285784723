import React from "react";
import "../styles/QueryResults.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { config } from '@fortawesome/fontawesome-svg-core';
import {
  faList,
  faMap,
  faGlobeEurope,
  faBuilding,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

class QueryResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }

    this.handleClickButton = this.handleClickButton.bind(this);
    this.handleMapClick = this.handleMapClick.bind(this);
    this.shortenString = this.shortenString.bind(this)
  }

  componentDidUpdate() {
  }
  
  defineSource() {
    if (this.props.filteredData && this.props.filteredData.length >= 0) {
      return this.props.filteredData;
    } else {
      return this.props.dataset;
    }
  }

  handleExternalUrlEnter = () => {
    if (
      this.props.dataset &&
      Object.keys(this.props.match.params) &&
      Object.keys(this.props.match.params).length !== 0
    ) {
      let object = this.props.dataset.find(
        (test) => this.props.match.params.project === test.name
      );
      if (object) {
        return this.props.determineViewParams(object);
      }
    } else {
      return;
    }
  };

  handleClickButton = (event) => {
    let length = event.target.id.length;
    let index = event.target.id.slice(10, length);
    let params = this.defineSource()[index];
    
    this.props.determineViewParams(params);
  };

  handleMapClick = (event) => {
    this.props.determineViewParams("MapView");
  };

  emptyMessage = () => {
    if (this.props.filteredData.length === 0) {
      return <h4>No data meeting the given criteria</h4>;
    }
  };

  shortenString = (str, maxLen) => {
    if (str.length <= maxLen) return str;
    return str.substr(0, str.lastIndexOf(" ", maxLen)) + " ...";
  }
  
  render() {
    config.autoAddCss = false
      return (
        
        <div className="ResultsWrapper">
          <div className="ResultsHeader">
            <div className="ResultsHeaderElement1">Available data</div>
            <div className="ResultsHeaderElement2">
              <FontAwesomeIcon icon={faList} /> List
            </div>
            <div className="ResultsHeaderElement2" onClick={this.handleMapClick}>
              <FontAwesomeIcon icon={faMap} /> Map
            </div>
          </div>
          <div className="ResultItem">
           
            {this.emptyMessage()}
            {this.handleExternalUrlEnter()}
            {this.defineSource().map((details, index) => (
              <div className="episodeBlock" key={index}>
                <h4>
                  {" "}
                  {details[this.props.metadata.Views.name]
                    ? details[this.props.metadata.Views.name]
                    : "..."}{" "}
                </h4>
                <div className="episodeParent">
                  <div className="episodeDescription">
                    <p>
                      {" "}
                      {details[this.props.metadata.Views.description]
                        ? this.props.metadata.Views.trimDescription ? this.shortenString(details[this.props.metadata.Views.description], 650) : details[this.props.metadata.Views.description]
                        : "..."}
                    </p>
                  </div>
                  <div className="episodeStats">
                    <span>
                      {" "}
                      <FontAwesomeIcon icon={faGlobeEurope} />{" "}
                      {details[this.props.metadata.Views.identifier1]
                        ? details[this.props.metadata.Views.identifier1]
                            .constructor === Array
                          ? details[this.props.metadata.Views.identifier1].map(
                              (item) => {
                                return item + " ";
                              }
                            )
                          : details[this.props.metadata.Views.identifier1]
                        : "..."}
                    </span>
                    <span>
                      {" "}
                      <FontAwesomeIcon icon={faBuilding} />{" "}
                      {details[this.props.metadata.Views.identifier2]
                        ? details[this.props.metadata.Views.identifier2]
                            .constructor === Array
                          ? details[this.props.metadata.Views.identifier2].map(
                              (item) => {
                                return item + " ";
                              }
                            )
                          : details[this.props.metadata.Views.identifier2]
                        : "..."}
                    </span>
                    <span>
                      {" "}
                      <FontAwesomeIcon icon={faDownload} />{" "}
                      {details[this.props.metadata.Views.identifier3]
                        ? details[this.props.metadata.Views.identifier3]
                            .constructor === Array
                          ? details[this.props.metadata.Views.identifier3].map(
                              (item) => {
                                return item + " ";
                              }
                            )
                          : details[this.props.metadata.Views.identifier3]
                        : "..."}
                    </span>
                  </div>
                </div>
                <div
                  className="dataButton"
                  onClick={this.handleClickButton}
                  id={"dataButton" + index}
                >
                  SHOW DATASET
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }    
  }


export default QueryResults;
