import axios from "axios";
import authHeader from "./auth-header";
import authService from "./auth.service";
import { configs } from"../config";

const API_URL = configs.PROTOCOL + configs.DOMAIN + configs.DATA_PORT + "/api/";

const getLocation = async () => {
  const res = await axios.get('https://geolocation-db.com/json/')
  return res.data;
}

const downloadOne = async (data) => {

 
  const userId = await authService.getCurrentUserId()
  const geolocation = await getLocation() 

  return axios
    .get(API_URL + "downloadOne", {
      headers: authHeader(),
      params: { _id: data._id, userId: userId, city: geolocation.city, country_name:geolocation.country_name, country_code:geolocation.country_code, longitude: geolocation.longitude, latitude: geolocation.latitude},
      responseType: "arraybuffer",
      onDownloadProgress: progressEvent => {
     
        let percentCompleted = Math.floor(progressEvent.loaded / progressEvent.total * 100)
        console.log("Downloading " + data.name + " | " + percentCompleted + "%")
      }
    })
    .then((response) => {
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      
      link.download = data.name;
      document.body.appendChild(link);
      link.click();
      //  this.setState({ downloading: false });
    })
    .catch((error) => {
      //   this.setState({ downloading: false });

      return [];
    });
};

const getStats = () => {
  return axios.get(API_URL + "analytics", {
    headers: authHeader()
  });
};


export default {
  downloadOne,
  getStats
};
