import React from "react";
import ReactDOM from "react-dom";
import { createStore } from "redux";
import { Provider } from "react-redux";
// import routes from "./components/router";
import "./index.css";
// import App from "./components/app/App.js";
import { unregister } from "./serviceWorker";
import reducer from "./store/reducer.js";
import { BrowserRouter, Switch } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
import MainView from "./scenes/main/index.js";
import Landing from "./scenes/landing/index.js";
import Login from "./scenes/login/index.js";
import Admin from "./scenes/admin/index.js";
import User from "./scenes/user/index.js";
import Password from "./components/Password.js";

// function App() {
//   const routeResult = useRoutes(routes);
//   return routeResult;
// }

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <PublicRoute restricted={false} component={Landing} path="/" exact />
      <PublicRoute restricted={false} component={MainView} path="/main" exact />
      <PublicRoute
        restricted={false}
        component={MainView}
        path="/main/:project"
        // exact
      />
      <PublicRoute restricted={true} component={Login} path="/login" exact />
      <PrivateRoute restricted={false} component={User} path="/user" exact />
      <PrivateRoute
        restricted={false}
        component={Password}
        path="/password"
        exact
      />
      <PrivateRoute restricted={true} component={Admin} path="/admin" exact />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
