import React from "react";
import "../styles/FilterBlock.css";
import FilterParams from "./FilterParams";

class FilterBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: "",
    };
  }

  createFilterBlocks(props) {
    let isFilterForDataSet = (element) => {
      return element.level === "main";
    };
    return this.props.filterList.filter(isFilterForDataSet).map((item) => (
      <React.Fragment key={item.name}>
        <FilterParams
          name={item.name}
          type={item.type}
          multiple={item.multiple}
          level={item.level}
          displayName={item.displayName}
          dataset={this.props.dataset}
          filterContent={this.props.filterContent}
          filteredDetails={this.props.filteredDetails}
          filteredData={this.props.filteredData}
          filterParameters={this.props.filterParameters}
          currentView={this.props.currentView}
        />
      </React.Fragment>
    ));
  }

  render() {
    return (
      <div className="FilterWrapper">
        <div className="FilterHeader">Filters</div>
        {/* {<FilterParams dataset={this.props.dataset}  selectedCountries={this.props.selectedCountries}  uniqueCountries={this.props.uniqueCountries}/> } */}
        {this.createFilterBlocks()}
      </div>
    );
  }
}
export default FilterBlock;
