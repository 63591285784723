import React, { useState, useEffect } from "react";
import "../styles/MapView.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { config } from '@fortawesome/fontawesome-svg-core';
import { faList, faMap } from "@fortawesome/free-solid-svg-icons";
// import { Map as LeafletMap, Marker, Popup, TileLayer } from "react-leaflet";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import L from "leaflet";

function MapView(props) {
  config.autoAddCss = false
  let navigateToList = () => {
    props.goBackToMain();
  };

  const [source, setSource] = useState([]);
  const [loading, setLoading] = useState(true)

  function onEachFeature(feature, layer) {
    // does this feature have a property named popupContent?
    if (feature.properties && feature.properties.popupContent) {
      layer.bindPopup(feature.properties.popupContent);
    }
  }

  function checkSource() {
    //Function that checks if any filter parametrs are present and accordingly set which data to display on map.
   // if (!Object.entries.hasOwnProperty(props.filteredData) || Object.entries(props.filteredData).length === 0) {
    if (Object.keys(props.filteredData).length === 0 || Object.entries(props.filteredData).length === 0) {
      setSource(props.dataset);

      setLoading(false)
    } else {
 
      setSource(props.filteredData);
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
  }, [props.filteredData]);

  useEffect(() => {
    checkSource()
  }, [loading===true]);


  let presentOnMap = () => {

    let obj = [];

    Promise.all(
      source.map((item, index) => {
        let isValidJSON = true;
        let tmp;
        try {
          tmp = JSON.parse(item.geoposition);
        } catch {
          isValidJSON = false;
        }

        if (isValidJSON) {
          tmp.map((m) => {
            m.type = "Feature";
            m.properties = { popupContent: item.name };
          });
          obj.push(tmp);
        }
      })
    );
    
    return [].concat.apply([], obj);
  };

  // let mapData = JSON.parse([\n{\ngeometry: {\ntype: \"Polygon\",\ncoordinates: [\n[[20.8516666, 52.097772],\n[21.271111, 52.097772],\n[21.271111, 52.368055],\n[20.8516666, 52.368055],\n]]}\n}\n])
  // ///Example of valid Map GeoJson
  // let mapData = [
  //   {
  //     type: "Feature",
  //     properties: {
  //       popupContent: "Polygon",
  //     },
  //     geometry: {
  //       type: "Polygon",
  //       coordinates: [
  //         [
  //           [15.954178, 51.526142],
  //           [17.931717, 51.389232],
  //           [17.494995, 50.464952],
  //           [15.973449, 50.784173],
  //           [15.954178, 51.526142],
  //         ],
  //       ],
  //     },
  //   },
  //   {
  //     type: "Feature",
  //     properties: {
  //       popupContent: "Profile line",
  //     },
  //     geometry: {
  //       type: "MultiLineString",
  //       coordinates: [
  //         [
  //           [17.947855, 54.466212],
  //           [19.661722, 53.978064],
  //           [22.737894, 54.02972],
  //         ],
  //       ],
  //     },
  //   },
  //   {
  //     type: "Feature",
  //     properties: {
  //       popupContent: "Profile line",
  //     },
  //     geometry: {
  //       type: "Polygon",
  //       coordinates: [
  //         [
  //           [20.195249, 52.079481],
  //           [22.975035, 50.21659],
  //         ],
  //       ],
  //     },
  //   },
  //   {
  //     type: "Feature",
  //     properties: {
  //       popupContent: "BOGDANKA: underground coal mining",
  //     },
  // geometry: {
  //   type: "Point",
  //   coordinates: [23.001038, 51.326522],
  // },
  //   },
  // ];

  let style = () => {
    return { color: "#ED5A48" };
  };

  let geojsonMarkerOptions = {
    radius: 5,
    fillColor: "green",
    color: "green",
    weight: 1,
    opacity: 1,
    fillOpacity: 0.8,
  };

  let pointToLayer = (feature, latlng) => {
    return L.circleMarker(latlng, geojsonMarkerOptions);
  };

  return (
    <div className="ResultsWrapper">
      <div className="ResultsHeader">
        <div className="ResultsHeaderElement1">Available data</div>
        <div className="ResultsHeaderElement2" onClick={navigateToList}>
          <FontAwesomeIcon icon={faList} /> List
        </div>
        <div className="ResultsHeaderElement2">
          <FontAwesomeIcon icon={faMap} /> Map
        </div>
      </div>
      <div className="ResultsBlock"></div>

      {/* Leaflet map container */}
      <MapContainer center={[51.505, 21.09]} zoom={5} scrollWheelZoom={true}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {console.log(loading)}
        {!loading ? 
        <GeoJSON
          data={presentOnMap()}
          onEachFeature={onEachFeature}
          style={style}
          pointToLayer={pointToLayer}
        /> : null}

        {/* <Marker position={[51.326522, 23.001038]}>
          <Popup></Popup>
        </Marker> */}
      </MapContainer>
    </div>
  );
}

export default MapView;
