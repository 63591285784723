import React, { useState, useEffect } from "react";
import "../styles/ProjectViewElement.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { config } from '@fortawesome/fontawesome-svg-core';
import {
  faFolderOpen,
  faFileAlt,
  faPlusSquare,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import userService from "../../../services/user.service";
import authService from "../../../services/auth.service";
import dataService from "../../../services/data.service";

function ProjectviewElement(props) {
  const [login, checkLogin] = useState(false);
  const [groups, setGroups] = useState([]);

  config.autoAddCss = false

  userService.isLogin().then((res, err) => {
    if (res.status === 200) {
      checkLogin(true);
    }
  });

  let itemType;

  let levelDown = (event) => {
    props.nesting({ _id: event.target.id });

    if (itemType === "file") {
      window.history.pushState({ _id: event.target.id }, "Sample Title", "");
    }
  };


  let checkGroups = () =>{  
  if (login) {
  authService.getUserGroups().then((res) => {
    setGroups(res.data.groups)
  });
 } else { 
  setGroups(['all'])
 }
}
  useEffect(() => {
    checkGroups()
  }, [login]);


  let determineType = () => {
    if (props.data.itemType === "directory") {
      itemType = "directory";
      return faFolderOpen;
    } else {
      itemType = "file";
      return faFileAlt;
    }
  };

  let addDataToDashboard = () => {
    let ale = () => {
      let message = "File " + props.data.name + " was added to My Datasets";
      alert(message);
    };

    function addEntry(event) {
      // Parse any JSON previously stored in allEntries
      var existingEntries = JSON.parse(localStorage.getItem("USER_ITEMS"));
      if (existingEntries == null) existingEntries = [];

      var input = {
        id: props.data.name,
        dataSet: props.parent.name,
        _id: props.data._id,
        allowedDownload: props.data.allowedDownload,
        userId: authService.getCurrentUserId()
      };

      localStorage.setItem("entry", JSON.stringify(input));
      // Save allEntries back to local storage
      existingEntries.push(input);
      localStorage.setItem("USER_ITEMS", JSON.stringify(existingEntries));

      ale();
    }

    if (props.data.itemType === "file" && login) {
      return (
        <img
          onClick={addEntry}
          className="addIcon"
          src="../../images/addIcon.png"
        ></img>
      );
    }
  };

  let handleFileTransfer = () => {
  
    dataService.downloadOne({ _id: props.data._id, name: props.data.name});
  };

  let findCommonElements = (arr1, arr2) => {


    if (arr1 && arr2) {
      return arr2.some((item) => arr1.includes(item));
    }
  };

  let downloadFile = () => {

    if (props.data.itemType === "file" && groups.length > 0) {
    
        if (findCommonElements(
        props.data.allowedDownload,
        groups)) {
      return (
        // <FontAwesomeIcon
        //   icon={faDownload}
        //   className="addDownloadIcon"
        //   onClick={handleFileTransfer}
        // />
        <img
        onClick={handleFileTransfer}
        className="addIconDownload"
        src="../../images/downloadIcon2.png"
      ></img>
      );
    }  else {
      return null
    }}
  };
  let prepareName = (name) => {

    let splitArray = name.split('.')

    if (splitArray[splitArray.length-1] != name) {
      let returnedName = name.substring(0, 45) + "(...)." + splitArray[splitArray.length -1] 
      return returnedName;
    } else {
      return name.substring;
    }
   

    
  }

  return (
    <div>
      <div
        key={"Key: " + props.data._id}
        className="projectViewDiv"
        id={props.data._id}
        onClick={levelDown}
      >
        <div
          className="projectViewDivInner"
          id={props.data._id}
          onClick={levelDown}
        >
          <FontAwesomeIcon
            className="fontAwesomeIcon"
            icon={determineType()}
            id={props.data._id}
            onClick={levelDown}
          />
          <span id={props.data._id} onClick={levelDown}>
            {props.data.name.length > 55 ?  prepareName(props.data.name) : props.data.name}
          </span>
          {addDataToDashboard(props.data.name)}
          {downloadFile()}
        </div>
      </div>
    </div>
  );
}

export default ProjectviewElement;