import React, { useState, useEffect } from "react";
import axios from "axios";
import "../styles/ProjectView.css";
import { configs } from"../../../config";
import ProjectViewElement from "./ProjectViewElement.js";
import ShowItem from "./ShowItem.js";
import { config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faMap,
  faAngleDoubleLeft,
  faTable,
} from "@fortawesome/free-solid-svg-icons";



//import { configs } from'@fortawesome/fontawesome-svg-core';

// Make sure this is before any other `fontawesome` API calls


function ProjectListView(props) {
  config.autoAddCss = false

  const [projectDetails, setProjectDetails] = useState([]);
  const [nestedId, setNestedId] = useState([]);
  const [level, setLevel] = useState(-1);
  const [itemID, showItem] = useState(1);


  let setCurrentView = (event) => {
    return event.target.id ? props.setView(event.target.id) : null;
  };

  let setChangeToTableMessage = () => {
    const icon = <FontAwesomeIcon icon={faTable} />;
    let composeMessage = (message) => {
      return (
        <div id="table">
          {icon}
          {message}
        </div>
      );
    };
    return props.isFiltered
      ? composeMessage(" View filtered files")
      : composeMessage(" View all " + props.numberOfFiles + " files");
  };

  useEffect(() => {
    let query;
    if (nestedId[0] === undefined) {
      query = { params: { _id: props.viewParams._id } };
    } else {
      query = { params: { _id: nestedId[level], level: "1" } };
    }

    axios
      .get(
        configs.PROTOCOL + configs.DOMAIN + configs.DATA_PORT + "/api/listfiles",
        query
      )
      .then((res, err) => {
        setProjectDetails(res.data.sort((a, b) => (a.name > b.name ? 1 : -1)));
      });
  }, [level, nestedId, props.viewParams._id]);

  let nesting = (props) => {
    let value;

    projectDetails.map((item) => {
      if (item._id === props._id) {
        if (item.itemType === "directory") {
          value = item._id;
          setNestedId([...nestedId, value]);
          setLevel(level + 1);
          showItem(1);
        }
        if (item.itemType === "file") {
          showItem(item._id);
        }
      }
      return null;
    });
  };

  let goBackButton = (props) => {
    let changeNestedState = (props) => {
      let ids = nestedId;
      let idsLength = ids.length - 1;
      ids.length = idsLength;
      setNestedId(ids);
      setLevel(level - 1);
      showItem(1);
      setProjectDetails([]);
    };

    if (level > -1) {
      return (
        <div className="BackButton" onClick={changeNestedState}>
          <FontAwesomeIcon icon={faAngleDoubleLeft} /> GO BACK
        </div>
      );
    }
  };
  return (
    <div className="ResultsWrapper">
      <div className="ResultsHeader">
        <div className="ResultsHeaderElement1">Available data</div>
        <div className="ResultsHeaderElement2" onClick={props.navigateToList}>
          <FontAwesomeIcon icon={faList} /> Main view
        </div>
        <div className="ResultsHeaderElement2" onClick={setCurrentView}>
          <div id="map">
            <FontAwesomeIcon icon={faMap} id="map" /> Map
          </div>
        </div>
      </div>
      <div className="ResultsBlock">
        <h3>{props.viewParams.name}</h3>
      </div>
      <div className="ResultsWrapper">
        <div>
          <h4>Description: </h4>
          <span>{props.viewParams.dataDescription || props.viewParams.description}</span>
        </div>

        <div className="ViewAllFilesButton" id="table" onClick={setCurrentView}>
          {!props.isLoading ? setChangeToTableMessage() : null}
        </div>

        <h4>Dataset:</h4>
        {goBackButton()}
        <div className="ProjectViewElements">
          {projectDetails.map((item) => (
            <ProjectViewElement
              key={item._id}
              data={item}
              parent={props.viewParams}
              nesting={nesting}
       
            />
          ))}
        </div>

        {(() => {
          if (itemID !== 1) {
            let e;
            projectDetails.map((item) => {
              if (item._id === itemID) {
                return (e = item);
              }
              return null;
            });
            return <ShowItem data={e} />;
          }
        })()}
      </div>
    </div>
  );
}

export default ProjectListView;
