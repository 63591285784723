import React from "react";
import axios from "axios";

//Import Utils functions
import Utils from "../../utils";
import { configs } from"../../config";
import authHeader from "../../services/auth-header";

//Import Components

import Header from "../../components/header/Header";
// import SearchBar from "./components/SearchBar";
import MainWrapper from "./components/MainWrapper";

export default class MainView extends React.Component {
  constructor(props) {
    super();
    this.state = {
      dataset: [],
      // Parameters to be obtained from admin panel
      filterList: [],
      ip: "",
      stringArr: "",
      loading: true,
      currentProject: "",
      metadata: {
        Views: {
          name: "",
          description: "",
          identifier1: "",
          identifier2: "",
          identifier3: "",
        },
        Table: {
          Column1: {
            attribute: "",
            displayName: "",
            undefined: "",
            type: "",
          },
          Column2: {
            attribute: "",
            displayName: "",
            type: "",
          },
          Column3: {
            attribute: "",
            displayName: "",
            type: "",
          },
          Column4: {
            attribute: "",
            displayName: "",
            type: "",
          },
        },
      },
    };
  }


  //creating function to load ip address from the API
  
  //  Defined in package.json adress proxy: /
  componentDidMount() {

    //Retrive current URL parameter to automatically display selected project from external link
    const { project } = this.props.match.params;
    this.setState({ currentProject: project });
    //....///

    Utils.loadSettings("project").then((value) => {
      this.setState({metadata: JSON.parse(value) })
     
    });

    this.retriveDatasetData();

    let query = { params: { view: "Filters" } };

    axios
      .get(
        configs.PROTOCOL + configs.DOMAIN + configs.DATA_PORT + "/api/settings",
        query
      )
      .then((res) => {
        this.setState({ filterList: res.data.FilterList });
        this.prepareSelectValues();
        this.retriveDatasetData();
      });
  }



  prepareSelectValues = () => {
    let arr = ["_id", "parentId", "geoposition"];

  //   const variables = JSON.parse(localStorage.getItem("variables1"));
    const variables = this.state.metadata;
    let VariablesNames = Object.getOwnPropertyNames(variables.Views);
    arr.push(
      this.state.filterList.map((item) => {
        return item.name;
      })
    );
    arr.push(
      VariablesNames.map((item) => {
        return variables.Views[item];
      })
    );
    let returnAttributes = () => {
      let obj = Object.values(variables.Table);
      return obj.map((i) => {
        return i.attribute;
      });
    };
    arr.push(returnAttributes());

   
    let unique = arr.flat().filter((v, i, a) => a.indexOf(v) === i)
    let stringArr = unique.join();
         
    stringArr = stringArr.replace(/\,\,+/g, ",");
    stringArr = stringArr.replace(/\,/g, " ");
    this.setState({ stringArr });
  
  
  };

  retriveDatasetData = () => {
    let query = {
      headers: authHeader(),
      params: { attributes: this.state.stringArr },
    };
    axios
      .get(
        configs.PROTOCOL + configs.DOMAIN + configs.DATA_PORT + "/api/listfiles",
        query
      )
      .then((res) => {
        const dataset = res.data;
        dataset.sort((a, b) => (a.name > b.name ? 1 : -1));
        this.setState({ dataset });
        this.setState({ loading: false });
        this.forceUpdate();
      });
  };

  render() {
    return (
      <div>
        <Header />
        <MainWrapper
          match={this.props.match}
          dataset={this.state.dataset}
          filterList={this.state.filterList}
          filterListProject={this.state.filterListProject}
          metadata={this.state.metadata}
          ip={this.state.ip}
          stringArr={this.state.stringArr}
        />
      </div>
    );
  }
}
