import React, { useEffect, useState } from "react";
import "../styles/ProjectView.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { config } from '@fortawesome/fontawesome-svg-core';
import userService from "../../../services/user.service";
import authService from "../../../services/auth.service";
import dataService from "../../../services/data.service";
import {
  faList,
  faMap,
  faFolderOpen,
  faPlusSquare,
  faDownload
} from "@fortawesome/free-solid-svg-icons";

function ProjectTableView(props) {

  config.autoAddCss = false
  const [dataSource, setDataSource] = useState(props.filesList);
  const [groups, setGroups] = useState([]);

  const [login, checkLogin] = useState(false);
  const [showAction, setShowAction] = useState(false)

  userService.isLogin().then((res, err) => {
    if (res.status === 200) {
      checkLogin(true);
    }
  });

  let checkGroups = () =>{  
    if (login) {
    authService.getUserGroups().then((res) => {
      setGroups(res.data.groups)
    });
   } else { 
    setGroups(['all'])
   }
  }
    useEffect(() => {
      checkGroups()
    }, [login]);

  let setCurrentView = (event) => {
    props.setView(event.target.id);
  };

  useEffect(() => {
    handleDisplayFiles();
    // eslint-disable-next-line
  }, [props.filteredFiles]);

  const handleDisplayFiles = () => {
    props.isFiltered === true && props.filteredFiles.length > 0
      ? setDataSource(props.filteredFiles)
      : setDataSource(props.filesList);
  };

  let ale = (name) => {
    let message = "File " + name + " was added to my datasets";
    alert(message);
  };

  function addEntry(event) {

    // Parse any JSON previously stored in allEntries
    var existingEntries = JSON.parse(localStorage.getItem("USER_ITEMS"));
    if (existingEntries == null) existingEntries = [];

    let identifier = event.target.id.split(",");
    
    const allowedDownload = props.filesList.find(item => item._id === identifier[2]).allowedDownload
 
    var input = {
      id: identifier[0],
      dataSet: identifier[1],
      _id: identifier[2],
      userId: authService.getCurrentUserId(),
      allowedDownload: allowedDownload
    };

    localStorage.setItem("entry", JSON.stringify(input));
    // Save allEntries back to local storage
    existingEntries.push(input);
    localStorage.setItem("USER_ITEMS", JSON.stringify(existingEntries));

    ale(input.id);
  }

  
  let handleFileTransfer = (id, name) => {
   // event.persist()
  
   dataService.downloadOne({ _id: id, name: name});
  };

  let findCommonElements = (arr1, arr2) => {

    if (arr1 && arr2) {
      return arr2.some((item) => arr1.includes(item));
    }
  };


  let downloadFile = (id, name, allowedDownload) => {

    if ( groups.length > 0) {
    
        if (findCommonElements(
        allowedDownload,
        groups)) {
          !showAction && setShowAction(true);
      return (
           <img
        onClick={event =>  handleFileTransfer(id, name)}
        className="addIcon"
        src="../../images/downloadIcon2.png"
      ></img>     
      );
    }  else {
      return null
    }}
  };

  return (
    <div className="ResultsWrapper">
      <div className="ResultsHeader">
        <div className="ResultsHeaderElement1">Available data</div>
        <div
          className="ResultsHeaderElement2"
          id="list"
          onClick={setCurrentView}
        >
          <FontAwesomeIcon icon={faList} /> List
        </div>
        <div className="ResultsHeaderElement2" onClick={setCurrentView}>
          <div id="map">
            <FontAwesomeIcon icon={faMap} id="map" /> Map
          </div>
        </div>
      </div>
      <div className="ResultsBlock">
        <h3>{props.viewParams.name}</h3>
      </div>
      <div className="ProjectTableViewWrapper">
        <div id="list" onClick={setCurrentView} className="ViewAllFilesButton">
          <FontAwesomeIcon icon={faFolderOpen} /> View directory tree
        </div>
        <table className="TableWithfiles">
          <tr>
            <td>{props.metadata.Table.Column1.displayName}</td>
            <td>{props.metadata.Table.Column2.displayName}</td>
            <td>{props.metadata.Table.Column3.displayName}</td>
            <td>{props.metadata.Table.Column4.displayName}</td>
            {/* <td>Parent directory</td> */}
            {login || showAction ? <td>Action</td> : null}
          </tr>

          {props.isFiltered === true && props.filteredFiles.length === 0 ? (
            <tr>No data meeting the given criteria</tr>
          ) : (
            dataSource.map((item) => {
              return (
                <tr>
                  <td>{item[props.metadata.Table.Column1.attribute]}</td>
                  <td>{item[props.metadata.Table.Column2.attribute]}</td>
                  <td>{item[props.metadata.Table.Column3.attribute]}</td>
                  <td>{item[props.metadata.Table.Column4.attribute]}</td>
                  {login ? (
                    <td>
                      <div>
                      <img
                        onClick={addEntry}
                        className="addIcon"
                        src="../../images/addIcon.png"
                        id={item.name + "," + item.dsEntryId + "," + item._id}
                      ></img>
                       {downloadFile(item._id, item.name, item.allowedDownload)}
                        </div>
                         
                    </td>
                  ) : <td>
                  <div>{downloadFile(item._id, item.name, item.allowedDownload)}</div>
                         
                         </td> }
                </tr>
              );
            })
          )}
        </table>
      </div>
    </div>
  );
}

export default ProjectTableView;
