import React, { useState, useRef } from "react";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import "./styles/styles.css";
import AuthService from "../../services/auth.service";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const Login = (props) => {
  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.login(username, password).then(
        () => {
          window.location.href = "/user";
        },
        (error) => {
          // const resMessage =
          //   (error.response &&
          //     error.response.data &&
          //     error.response.data.message) ||
          //   error.message ||
          //   error.toString();

          setLoading(false);
          setMessage("Incorrect credentials");
        }
      );
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="wrapper">
      <div className="center">
        <div className="logoBlock">
          <div className="imageWrapper">
            <a href="/">
              <img src="../images/logo.png" alt="logo" className="logoImage" />
            </a>

            <Form onSubmit={handleLogin} className="formField" ref={form}>
              <input
                type="text"
                className="inputField"
                name="username"
                placeholder="USERNAME"
                value={username}
                onChange={onChangeUsername}
                validations={[required]}
              ></input>

              <input
                type="password"
                className="inputField"
                name="password"
                placeholder="PASSWORD"
                value={password}
                onChange={onChangePassword}
                validations={[required]}
              ></input>

              <button className="button" disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Login</span>
              </button>

              {message && (
                <div className="form-alert">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
              <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
