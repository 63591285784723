import React from "react";
import "../styles/ShowItem.css";

function ShowItem(props) {
  let dataKeys = Object.getOwnPropertyNames(props.data);
  let data = props.data;
  return (
    <div className="itemDetails">
      {dataKeys.map((item) => {
     console.log(data[item])
        if (item !== "_id" && item !== "downloadId" && item !== "parentId" && item !== "geoposition") {
          if (data[item] !== null || data[item].isArray ? data[item].length !== 0 : false) {
            return (
              <span key={item}>
                <b>{item}</b>
                {" : " + data[item]}
              </span>
            );
          }
        } else {
          return null;
        }
      })}
    </div>
  );
}

export default ShowItem;
