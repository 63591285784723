import React from "react";
import Button from "../../../components/buttons/button";
// import styled from "styled-components";
import "./header.css";

class Header extends React.Component {
  render() {
    // const Logo = styled.img`
    //   margin-left: 49px;
    //   margin-top: 16px;
    // `;

    let ToogleState = () => {
      let status = JSON.parse(localStorage.getItem("user"));
      if (status !== null) {
        return (
          <Button
            name={"MY ACCOUNT"}
            href={"/user"}
            cssClass={"navigationButton loginButton"}
          />
        );
      } else {
        return (
          <Button
            name={"LOG IN"}
            href={"/login"}
            cssClass={"navigationButton loginButton"}
          />
        );
      }
    };
    //
    return (
      <div className="landing">
        {/* Revrite logo as div with background property */}
        <div className="headerWrapper">
          {/* <Logo src="../../../../images/logo.png"></Logo> */}
          <div className="logoBlockLanding">
            <a href="/">
              <img src="../images/logo.png" alt="logo" className="logoImageLanding" />
            </a>
          </div>
          <div className="linkWrapper">
            <Button
              name={"DATABASE"}
              href={"/main"}
              cssClass={"navigationButton databaseButton"}
            />
            {ToogleState()}
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
