import React, { useState } from "react";
import "../styles/account.css";
import UserData from "./UserData.js";
import DataSets from "./DataSets.js";
import Utils from "../../../utils";

function Account(props) {
  const [page, setPage] = useState("DataSets");

  let toogle = () => {
    let render;
    if (page === "DataSets") {
      render = <DataSets />;
    }
    if (page === "UserData") {
      render = <UserData />;
    }
    return render;
  };

  let setCurrentToogle = (event) => {
    return setPage(event.target.id);
  };

  return (
    <div className="UserPanel">
      <h3>User panel</h3>
      <div className="ToogleWrapper" onClick={setCurrentToogle}>
        <div
          id="DataSets"
          className={page === "DataSets" ? "Toogle Active" : "Toogle"}
        >
          <span id="DataSets">My datasets</span>
        </div>
        <div
          id="UserData"
          className={page === "UserData" ? "Toogle Active" : "Toogle"}
        >
          <span id="UserData">Account details</span>
        </div>
        <a href="/admin" id="Admin">
          <div className="Toogle">
            {Utils.isAdmin() ? <span>Administration</span> : null}
          </div>
        </a>
      </div>
      {toogle()}
    </div>
  );
}

export default Account;
