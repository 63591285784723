import React from "react";
import "./styles/styles.css";
import Header from "./components/header";
import MainBody from "./components/mainBody";

class Landing extends React.Component {
  render() {
    return (
      <div className="Landing">
        <Header />
        <MainBody />
      </div>
    );
  }
}

export default Landing;
